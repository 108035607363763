const TextStyles = {
  variants: {
    title1: {
      fontSize: '30px',
      color: 'black',
      as: 'b',
    },
    title2: {
      fontSize: '24px',
      color: 'black',
      as: 'b',
    },
    title3: {
      fontSize: '20px',
      color: 'black',
      as: 'b',
    },
    title4: {
      fontSize: '18px',
      color: 'black',
      as: 'b',
    },
    title5: {
      fontSize: '16px',
      color: 'black',
      as: 'b',
    },
    subtitle1: {
      fontSize: '24px',
      color: 'black',
    },
    info1: {
      fontSize: '18px',
      color: 'black',
    },
    info2: {
      fontSize: '16px',
      color: 'black',
    },
    info3: {
      fontSize: '14px',
      color: 'black',
    },
    info4: {
      fontSize: '12px',
      color: 'black',
    },
    tableContent: {
      fontSize: '18px',
      color: '#4A4A4A',
    },
    hintSubtitle: {
      fontSize: '12px',
      color: '#9C9C9C',
      as: 'b',
    },
    hintInfo: {
      fontSize: '11px',
      color: '#9C9C9C',
      as: 'b',
    },
    cardInfo: {
        fontSize: '16px',
        color: 'black',
    },
    cardInfoBold: {
        fontSize: '19px',
        color: 'black',
        fontWeight: 'semibold'
    },
    },
    defaultProps: {
      variant: 'info1',
    },
};

export default TextStyles;
