import { Box, Button, Card, CardBody, Flex, Input, Select, Text } from "@chakra-ui/react"
import { useState, useEffect, useContext } from "react"
import { CompanyResult } from "../../../Api/interfaces/company"
import { CostCenterResult } from "../../../Api/interfaces/costCenter"
import { GetAllCompanies } from "../../../Api/services/company"
import { GetAllCostCenter } from "../../../Api/services/costCenter"
import { UserContext } from "../../../Context/user.context"
import { IAllOpts } from "../../../Interfaces/IAllOpts"
import { IFilter } from "../../../Interfaces/IFilter"
import { formatDate } from "../../../utils/formatDate"
import parseJwt from "../../../utils/parseJwt"
import SearchSelect from "../../SearchSelect"
import { IPropsDefaultFilter } from "./interfaces/IProps"

const dateInputStyle = {
  '::-webkit-calendar-picker-indicator': {
    background: 'transparent',
    bottom: 0,
    color: 'transparent',
    cursor: 'pointer',
    height: 'auto',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 'auto',
  },
};

const DefaultFilter = (
  {
    // costCenter = true,
    // beneflex= true,
    // company = true,
    // companyFull = true,
    provider = true,
    racesHeld = true,
    onClickFilter,
    // multiCompany = true,
  }: IPropsDefaultFilter
) => {
    // COMPANY    
    //   const [companiesSelect, setCompaniesSelect] = useState<(CompanyResult | IAllOpts)[]>([])

    //   const [optsCompany, setOptsCompany] = useState<(CompanyResult | IAllOpts)[]>([])
    //   const [optCompanySelect, setOptCompanySelect] = useState<CompanyResult | null>(null)  
    //   const {userID} = useContext(UserContext)
      
    //   const getOptsCompany = async () => {
    //     const res = await GetAllCompanies(userID)
        
    //     const allOpts: IAllOpts = {
    //       id: 0,
    //       name: 'Todos'
    //     }

    //     setOptsCompany([allOpts, ...res])
    //   }    

    //   const handleSelectCompany = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     if(e.target.value === 'Todos'){      
    //       setOptCompanySelect(null)
    //       return  
    //     }

    //     setOptCompanySelect(JSON.parse(e.target.value))
    //   }
    // // -------------

    // // COST CENTER
    //   const [optsCostCenter, setOptsCostCenter] = useState<CostCenterResult[]>([])
    //   const [optCostCenterSelect, setOptCostCenterSelect] = useState<CostCenterResult | null>(null)

    //   const getOptsCostCenter = async () => {
    //     setOptCostCenterSelect(null)
    //     setOptsCostCenter([])
    //     if(!optCompanySelect && companiesSelect.length === 0){          
    //       return
    //     }

    //     if(optCompanySelect){
    //       const res = await GetAllCostCenter(optCompanySelect.companyID)
    //       setOptsCostCenter(res)
    //       return
    //     }

    //     if(companiesSelect.length === 1 && 'companyID' in companiesSelect[0]){
    //       const res = await GetAllCostCenter(companiesSelect[0].companyID)
    //       setOptsCostCenter(res)
    //       return
    //     }
    //   }

    //   const handleSelectCostCenter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     if(!e.target.value){      
    //       setOptCostCenterSelect(null)
    //       return  
    //     }

    //     setOptCostCenterSelect(JSON.parse(e.target.value))
    //   }
    // -------------   
    
    // DATE
      const [dateStart, setDateStart] = useState<string>('')
      const [dateEnd, setDateEnd] = useState<string>('')

      const handleDate = (e: any, start?: boolean) => {
        if(start){
          setDateStart(e.target.value)
          return
        }

        setDateEnd(e.target.value)
      }
    // -------------

    // // PROVIDER
    //   const [providerSelect, setProviderSelect] = useState<string>()

    //   const handleProvider = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     setProviderSelect(e.target.value)
    //   } 
    // // -------------

    //   // BENEFLEX
    //   const [beneflexSelect, setBeneflexSelect] = useState<string>()

    //   const handleBeneflex = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     setBeneflexSelect(e.target.value)
    //   } 
    // // -------------

    // // RACES HELD
    //   const [racesHeldSelect, setRacesHeldSelect] = useState<string>()

    //   const handleRacesHeld = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     setRacesHeldSelect(e.target.value)
    //   } 
    // -------------

    const handleClick = () => {
      
      console.log(dateStart)
      console.log(dateEnd)
      const filter: IFilter = {
        // company: optCompanySelect?.companyID || '0',
        // companies: companiesSelect.length > 0 ? companiesSelect.map(select => 'companyID' in select ? select.companyID : '0') : ['0'],
        // costCenter: optCostCenterSelect?.costCenterID || 0,
        periodStart: formatDate(dateStart),
        periodEnd: formatDate(dateEnd),
        // provider: providerSelect || '0',
        // beneblex: beneflexSelect || '0',
        // races_held: racesHeldSelect || '0'
      }
      console.log(filter)
      onClickFilter && onClickFilter(filter)
      
    }

    // useEffect(() => {
    //   getOptsCompany()
    // }, [])

    // useEffect(() => {
    //   getOptsCostCenter()
    // }, [optCompanySelect, companiesSelect])

    return (
        <Card marginBottom={10} width={'100%'}>
          <CardBody width={'100%'}>
            <Flex width={'100%'} gap={'20px'} alignItems="flex-start" direction={'column'}>
              {/* {
                companyFull &&
                  <Flex gap="2rem" width={'100%'} justifyContent={'space-between'} alignItems="center" marginBottom={5} position='relative'>
                    <Text>Empresa:</Text>
                    <Select placeholder="Todos" variant="outline" width={'100%'}>
                      {
                        optsCompany.map((opt: (CompanyResult | IAllOpts), idx: number) => 
                          <option key={idx} value={JSON.stringify(opt)}>{opt.name}</option>
                        )
                      }
                    </Select>
                  </Flex>
              } */}
              <Flex width={'100%'} direction='row'>
                <Flex width="50%" direction={'column'}>
                {/* {company &&
                  <Flex gap="2rem" width={'100%'} justifyContent={'flex-start'} alignItems="center" marginBottom={5} position='relative'>
                    <Text>Empresa:</Text>
                    {
                      multiCompany ? 
                        <SearchSelect allOpts={optsCompany} select={companiesSelect} setSelect={setCompaniesSelect} />
                        :
                        <Select placeholder="Todos" variant="outline" onChange={handleSelectCompany}>
                          {
                            optsCompany.map((opt: (CompanyResult | IAllOpts), idx: number) => 
                              <option key={idx} value={JSON.stringify(opt)}>{opt.name}</option>
                            )
                          }
                        </Select>
                    }    
                  </Flex>
                  }                  */}
                  <Flex alignItems="center" gap={2}>
                    <Text>Período:</Text>
                    <Input
                      max={dateEnd}
                      placeholder="Select Date and Time"
                      size="md"
                      type="date"
                      sx={dateInputStyle}
                      onChange={e => handleDate(e, true)}
                    />
                    <Text>Até</Text>
                    <Input
                      min={dateStart}
                      placeholder="Select Date and Time"
                      size="md"
                      type="date"
                      sx={dateInputStyle}
                      onChange={handleDate}
                    />
                  </Flex>
                </Flex>
                <Flex width="50%" direction={'column'}>
                  {/* { costCenter &&
                  <Flex gap="2rem" width={'100%'} justifyContent={'flex-end'} alignItems="center" marginBottom={5}>
                    <Text>Centro de Custo:</Text>
                    <Select placeholder="Todos" variant="outline" width="50%" onChange={handleSelectCostCenter}>
                      {
                        optsCostCenter.map((opt: CostCenterResult, idx: number) => 
                          <option key={idx} value={JSON.stringify(opt)}>{opt.description}</option>
                        )
                      }
                    </Select>
                  </Flex>
                  } */}
                  {/* {
                    provider &&
                      <Flex gap="2rem" width={'100%'} justifyContent={'flex-end'} alignItems="center" marginBottom={5}>
                        <Text>Fornecedor:</Text>
                        <Select placeholder="Todos" variant="outline" width={'50%'} onChange={handleProvider}>
                          <option value="2">UBER</option>
                          <option value="3">99</option>
                          <option value="4">Cabify</option>
                          <option value="5">Wappa</option>
                          <option value="12">Táxi 2.0</option>
                          <option value="13">Aerocoop</option>
                          <option value="16">Copsind</option>
                          <option value="17">TranspaTaxi RIO</option>
                          <option value="18">CoopDuque</option>
                          <option value="19">Taxi 01</option>
                          <option value="20">Barra Service</option>
                          <option value="21">COOPATAXI</option>
                          <option value="22">Radio Taxi Auracária</option>
                          <option value="23">Cooper Taxi Santos (ACAT)</option>
                          <option value="24">Nacional Taxi Sergipe</option>
                          <option value="25">Ligue Taxi</option>
                          <option value="26">FlashDriver</option>
                          <option value="27">ABC Radio Taxi</option>
                          <option value="28">Ouro Taxi RJ</option>
                          <option value="29">Use Taxi SP</option>
                          <option value="30">Empresa 3S</option>
                          <option value="31">COOMAT</option>                    
                        </Select>
                      </Flex>
                  } */}
                  {/* {
                    racesHeld && 
                      <Flex gap="2rem" width={'100%'} justifyContent={'flex-end'} alignItems="center" marginBottom={5}>
                        <Text>Corridas realizadas:</Text>
                        <Select placeholder="Todos" variant="outline" width={'50%'} onChange={handleRacesHeld}>
                          <option value="1">Por dentro</option>
                          <option value="2">Por fora</option>           
                        </Select>
                      </Flex>
                  } */}
                  {/* {
                    beneflex &&
                  <Flex gap="2rem" width={'100%'} justifyContent={'flex-end'} alignItems="center" marginBottom={5}>
                    <Text>BeneFlex:</Text>
                    <Select placeholder="Todos" variant="outline" width={'50%'} onChange={handleBeneflex}>
                      <option value="Beneflex">BeneFlex</option>
                      <option value="Corporativo">Corporativo</option>           
                    </Select>
                  </Flex>
                  } */}
                </Flex>
              </Flex>
            </Flex>
            <Flex justifyContent={'flex-end'}>
              <Button onClick={handleClick}>Filtrar</Button>
            </Flex>
          </CardBody>
        </Card>
    )
}

export default DefaultFilter


