import { Button, Input, Link, Modal } from "@chakra-ui/react"
import EmptyModal from "../Modal"
import FormInput from "../Form/Input"

interface loginModalProps {
    isOpen: boolean,
    onClose: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function LoginModal({isOpen, onClose}: loginModalProps) {
    return (
        <EmptyModal
        isOpen={isOpen}
        title={'Cadastrar Moeda'}
        onClose={() => onClose}
        >   
            <Input/>
            <Input/>
            <Button>Login</Button>   
            <Link>Cadastrar</Link>
        </EmptyModal>
    )
}