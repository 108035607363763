import { UseFormReturn } from 'react-hook-form';
import { Stack, StackDivider, IconButton, StackProps } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

import Form from '../Form';
import FormSelect from '../Select';
import FormInput from '../Input';

import { LodgingSearchFormValues } from '../../../Types/lodgings';

interface LodgingSearchFormProps {
  form: UseFormReturn<LodgingSearchFormValues, any>;
  onSubmit: (data: LodgingSearchFormValues) => Promise<void>;
  stackProps?: StackProps;
}

export default function LodgingSearchForm({
  form,
  onSubmit,
  stackProps,
}: LodgingSearchFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        {...stackProps}
        direction={'column'}
        divider={<StackDivider borderColor={'black'} />}
      >
        <Stack direction={'row'} spacing={2} maxW={500}>
          <FormSelect
            rhfRegister={{ ...register('numberOfAdults') }}
            errorState={errors.numberOfAdults}
          >
            <option value={1}>1 adulto</option>
            <option value={2}>2 adultos</option>
            <option value={3}>3 adultos</option>
          </FormSelect>
          <FormSelect
            rhfRegister={{ ...register('numberOfAccommodation') }}
            errorState={errors.numberOfAccommodation}
          >
            <option value={1}>1 acomodação</option>
            <option value={2}>2 acomodações</option>
            <option value={3}>3 acomodações</option>
          </FormSelect>
        </Stack>

        <Stack>
          <Stack direction={'row'} spacing={3}>
            <FormSelect
              rhfRegister={{ ...register('city') }}
              selectProps={{ variant: 'outline' }}
            >
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </FormSelect>

            <FormInput
              rhfRegister={{ ...register('startDate') }}
              inputProps={{ type: 'date' }}
            />
            <FormInput
              rhfRegister={{ ...register('endDate') }}
              inputProps={{ type: 'date' }}
            />

            <IconButton
              variant={'search'}
              aria-label="Procurar tabelas"
              icon={<SearchIcon />}
              type="submit"
            />
          </Stack>
        </Stack>
      </Stack>
    </Form>
  );
}
