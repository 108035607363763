import { useState, useContext } from 'react';
import {
  Stack,
  Flex,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Card,
  Link,
  Image,
  Heading,
  CardBody,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineUserCircle, HiMenu } from 'react-icons/hi';
import { CardContent, DivClose, TextLink } from './styles';
import logo from '../../Assets/logo.png';
import LoginModal from '../Login';
import { UserContext } from '../../Context/user.context';

const Header = () => {
  const navigate = useNavigate();
  const [openDriverOption, setOpenDriverOption] = useState(false);
  const [openExpenOption, setOpenExpenOption] = useState(false);
  const [openAdmrOption, setOpenAdmOption] = useState(false);
  const [openCharterOption, setOpenCharterOption] = useState(false);
  const [openFleetOption, setOpenFleetOption] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const { logout } = useContext(UserContext);

  const handleOpenDriver = () => {
    setOpenDriverOption(!openDriverOption);
    setOpenExpenOption(false);
    setOpenAdmOption(false);
    setOpenCharterOption(false);
    setOpenFleetOption(false);
  };

  const handleOpenExpen = () => {
    setOpenExpenOption(!openExpenOption);
    setOpenDriverOption(false);
    setOpenAdmOption(false);
    setOpenCharterOption(false);
    setOpenFleetOption(false);
  };

  const handleOpenAdm = () => {
    setOpenAdmOption(!openAdmrOption);
    setOpenDriverOption(false);
    setOpenExpenOption(false);
    setOpenCharterOption(false);
    setOpenFleetOption(false);
  };

  const handleOpenCharter = () => {
    setOpenCharterOption(!openCharterOption);
    setOpenAdmOption(false);
    setOpenDriverOption(false);
    setOpenExpenOption(false);
    setOpenFleetOption(false);
  };

  const handleOpenFleet = () => {
    setOpenFleetOption(!openFleetOption);
    setOpenAdmOption(false);
    setOpenDriverOption(false);
    setOpenExpenOption(false);
    setOpenCharterOption(false);
  };

  const CloseModais = () => {
    setOpenAdmOption(false);
    setOpenDriverOption(false);
    setOpenExpenOption(false);
    setOpenCharterOption(false);
    setOpenFleetOption(false);
  };

  const location = window.location.href;

  return (
    <Flex direction="column" position="relative">
      <style>
        {`
          .line {
            text-decoration: underline;
            text-decoration-color: #ff7300;
            text-decoration-thickness: 3px;
          }
          .line:hover {
            text-decoration: underline;
            text-decoration-color: #ff7300;
            text-decoration-thickness: 3px;
          }

          .a {
            transition: 0.3s ease;
            text-decoration-color: #ffffff;
          }
          .a:hover{
            text-decoration: underline;
            text-decoration-color: #ff7300;
            text-decoration-thickness: 3px;
          }
        `}
      </style>

      <Flex align="center" justifyContent="flex-end" padding={1} bg={'#ababab'}>
        <Box
          style={{ marginLeft: 'auto' }}
          display={{ base: 'none', md: 'flex' }}
        >
          <Menu>
            <MenuButton
              bg="transparent"
              color="#1A202C"
              onClick={() => setOpenLogin(false)}
            >
               <Flex alignItems="center" gap={3} marginRight="50px">
                <HiOutlineUserCircle size={40} color="#fff" />
              </Flex> 
            </MenuButton>
            <MenuList>
              <MenuItem onClick= {logout}  marginTop={5}>Sair</MenuItem>
            </MenuList>
            <LoginModal
              isOpen={openLogin}
              onClose={() => setOpenLogin(false)}
            />
          </Menu>
        </Box>
      </Flex>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="5px 20px"
        bg="#fff"
        color="#000"
        boxShadow="0px 2px 10px -2px rgba(0, 0, 0, 0.25)"
      >
        <Flex align="center" mr={5} marginRight={20}>
          <Image src={logo} width={100} />
        </Flex>
        <Flex
          gap={2}
          style={{ marginLeft: 'auto' }}
          display={{ base: 'flex', md: 'none' }}
        >
          <Menu>
            <MenuButton bg="transparent" color="#1A202C">
              <Flex alignItems="center" gap={3}>
                <HiOutlineUserCircle size={40} />
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem marginTop={5}>Sair</MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton bg="transparent" color="#1A202C">
              <Flex alignItems="center" gap={3}>
                <HiMenu size={40} />
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem marginTop={5}>Sair</MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          width={{ base: 'full', md: 'auto' }}
          alignItems="center"
          flexGrow={1}
          gap={5}
          mt={{ base: 4, md: 0 }}
        >
          <Link
            className={location == 'http://localhost:3000/' ? 'line' : 'a'}
            href="/"
          >
            Início
          </Link>
          <TextLink
            className={location.includes('/driver') ? 'line' : 'a'}
            onClick={handleOpenDriver}
          >
            Driver
          </TextLink>
          <Link
            className={location.includes('/dashboard') ? 'line' : 'a'}
            href="/dashboard"
          >
            Dashboard
          </Link>
        </Stack>
      </Flex>

      {openDriverOption && (
        <Card boxShadow={'rgba(0, 0, 0, 0.24) 0px 7px 8px'} zIndex={2}>
          <CardBody>
            <Stack spacing="4" direction="row" justifyContent="space-evenly">
              <Flex direction="column">
                <Link href="/driver/corridas-realizadas" pt="2" fontSize="sm">
                  Corridas Realizadas
                </Link>
              </Flex>
            </Stack>
          </CardBody>
        </Card>
      )}

      {(openDriverOption ||
        openExpenOption ||
        openAdmrOption ||
        openCharterOption ||
        openFleetOption) && <DivClose onClick={CloseModais} />}
    </Flex>
  );
};

export default Header;
