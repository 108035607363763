import { useContext } from "react";
import { ApiRepository, ApiResponse } from "../../../..";
import { UserContext } from "../../../../../Context/user.context";
import { IFilter } from "../../../../../Interfaces/IFilter";
import { ProjectReportResult } from "../../../../interfaces/driver/project";
import { ReceiptResult, ReportBasicFilterRequest } from "../../../../interfaces/driver/receipt";
import { ReportRideRequest, RideEconomyReportResult, RideReportResult } from "../../../../interfaces/driver/ride";
import { GetAllCompanies } from "../../../company";
import { GetProject } from "../../project";
import { Filter } from "../../receipt";
import { GetRideEconomy } from "../../rideEconomy";


//REQUISIÇÃO PARA A TELA ECONOMIA POR CORRIDA 
export async function ListRideEconomy(userID: any, filter?: IFilter) {

    let finalreport: RideEconomyReportResult[] = [];
    
    const req: ReportBasicFilterRequest = {
        UserID: userID,
        CompanyID:undefined,
        Companies:filter?.companies,
        CostCenterID: filter?.costCenter,
        StartDate: filter?.periodStart,
        EndDate:  filter?.periodEnd,
        Productive: undefined,
        PaymentMethod: filter?.beneblex,
        providerid: filter?.provider,
        ReceiptStatusID:undefined,
        TransactionID: undefined
    }

    if (req.Companies?.some(item => item === "0") )
    {    
          const companies = await GetAllCompanies(userID);  

          for (const company of companies || []) {
            req.CompanyID = company.companyID

                req.ReceiptStatusID = 3;
                let resReport2 = await GetRideEconomy(req);
                finalreport = [...finalreport, ...resReport2];

                req.ReceiptStatusID = 13;
                resReport2 = await GetRideEconomy(req);
                finalreport = [...finalreport, ...resReport2];
              }
    }
  else
    {      
        for (const company of req.Companies || []) {
            req.CompanyID = company;
           
              req.ReceiptStatusID = 3;
              let resReport2 = await GetRideEconomy(req);
              finalreport = [...finalreport, ...resReport2];

              req.ReceiptStatusID = 13;
              resReport2 = await GetRideEconomy(req);
              finalreport = [...finalreport, ...resReport2];           
        } 
    }
    return finalreport
}