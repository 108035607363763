export function formatDate(date?: string) {
    if (!date) {
        return '';
    }

    const d = new Date(date);
    const offsetMinutes = d.getTimezoneOffset();
    
    d.setMinutes(d.getMinutes() + offsetMinutes);

    const year = d.getUTCFullYear().toString();
    const month = (d.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = d.getUTCDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}
