import { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react';

import PageContainer from '../../../Components/Container/PageContainer';
import GradeView from '../../../Components/GradeView';
import Loading from '../../../Components/Loading';
import HotelConvenienceCard from '../../../Components/Card/HotelConvenienceCard';
import { DataTable } from '../../../Components/Table';
import { useHotelAccomodationsColumns } from './TableData';
// import Carousel from '../../../Components/Carousel';
import Slider from 'react-slick';

const mockImage =
  'https://media.istockphoto.com/id/503016934/fr/photo/entr%C3%A9e-de-lh%C3%B4tel-de-luxe.jpg?s=612x612&w=0&k=20&c=MLhfwuuPp85bBuX5DwEZBaFPx8TIkGMpL5gt6d_Xths=';

const mockImagesHotel = [
  { 
    url: 'https://images7.alphacoders.com/362/362619.jpg'
  },
  { 
    url: 'https://wallpaperboat.com/wp-content/uploads/2019/11/hotel-01.jpg'
  },
  { 
    url: 'https://c4.wallpaperflare.com/wallpaper/624/380/1000/life-resort-hotel-resort-hotel-wallpaper-preview.jpg'
  },
  { 
    url: 'https://wallpapercave.com/wp/wp6957260.jpg'
  },
  { 
    url: 'https://images.unsplash.com/photo-1625244724120-1fd1d34d00f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80'
  },
  { 
    url: 'https://wallpapershome.com/images/pages/ico_h/6498.jpg'
  }
]

export default function LodgingDetails() {
  const refCarousel = useRef<Slider>(null)

  const [loading, setLoading] = useState(true);
  const columns = useHotelAccomodationsColumns();
  const [data] = useState({
    hotel: {
      id: '111aa',
      name: 'Hotelzão',
      grade: 4,
      cep: '70705-040',
      uf: 'SP',
      city: 'Brasília',
      street: 'Rua dos bobos',
      number: '513',
      rental: '250,00',
      downtownDistance: '2km',
      checkIn: '12:00',
      checkOut: '14:00',
      conveniences: [
        'Wi-Fi',
        'Ar-condicionado',
        'Academia',
        'Recepção 24hrs',
        'Estacionamento',
        'Café da manhã',
      ],
      accomodations: [
        { type: 'Apartamento', capacity: 2, rental: '230' },
        { type: 'Apartamento Duplo', capacity: 4, rental: '280' },
      ],
    },
  });

  const mapProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <PageContainer>
      {loading && <Loading />}
      {!loading && (
        <>
          <Flex gap={6} mb={12}>
            <Stack>
              <HStack gap={6}>
                <Heading>{data.hotel.name}</Heading>
                <GradeView hotelGrade={data.hotel.grade} />
              </HStack>
              <Box>
                <Text>
                  {data.hotel.street}, {data.hotel.number}, {data.hotel.city}{' '}
                  {data.hotel.cep}
                </Text>
                <Text>{data.hotel.downtownDistance} do centro</Text>
              </Box>
            </Stack>
            <Spacer />
            <Stack>
              <Box>
                <Text variant="title2">R$ {data.hotel.rental}</Text>
                <Text mt={-2}>a diária</Text>
              </Box>

              <Button>Reservar</Button>
            </Stack>
          </Flex>

          <Flex gap={12} mb={12}>
            <Stack flex={2 / 5}>
              {/* <Carousel
                w={'350px'}
                h={'310px'}
                borderRadius={6}
                alt='Imagem selecionada do Hotel'
                urls={mockImagesHotel.map(item => item.url)}
                ref={refCarousel}
              /> */}
              <HStack maxW={'350px'} style={{overflow: "auto"}} pb={'10px'} >
                {
                  mockImagesHotel.map((img, idx) => (
                    <Image
                      key={idx}                      
                      onClick={() => {
                        refCarousel.current?.slickGoTo(idx)
                      }}
                      boxSize={'60px'}
                      borderRadius={6}
                      maxW={24}
                      src={img.url}                        
                      style={{cursor: 'pointer'}}
                    />
                  ))
                }
              </HStack>
            </Stack>

            <HotelConvenienceCard
              flex={3 / 5}
              conveniences={data.hotel.conveniences}
              checkIn={data.hotel.checkIn}
              checkOut={data.hotel.checkOut}
            />
          </Flex>

          <DataTable
            mb={12}
            data={data.hotel.accomodations}
            columns={columns}
          />

          <Heading mb={6}>Localização</Heading>
        </>
      )}
    </PageContainer>
  );
}
