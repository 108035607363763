import { ApiRepository, ApiResponse } from "../../..";
import { IFilter } from "../../../../Interfaces/IFilter";

export async function ListRide(userID: any, filter?: IFilter) {
    
    let api = new ApiRepository(2);
  
    const res = await api.apiRequestWrapper<ApiResponse<number>>({
        method: 'get',
        url: `Report/ListRide?StartDate=${filter?.periodStart}&EndDate=${filter?.periodEnd}&providerid=${filter?.providerid}}`,
    });
    return res;
}