import { ApiRepository, ApiResponse } from "../../..";
import { ReportBasicFilterRequest } from "../../../interfaces/driver/receipt";
import { ReportRideRequest, RideEconomyReportResult, RideFeeEconomyReportResult, RideReportResult } from "../../../interfaces/driver/ride";

export async function GetFeeRideEconomy(req: ReportBasicFilterRequest) {
    const api = new ApiRepository(3);

    let queryfilter = "Reports/RideFeeEconomy?companyID=" + req.CompanyID + "&startDate=" + req.StartDate + "&endDate=" + req.EndDate;

    if (req.CostCenterID != 0)
        queryfilter += "&CostCenterID=" + req.CostCenterID;
    if (req.PaymentMethod != "0")
        queryfilter += "&PaymentMethod=" + req.PaymentMethod;

    const res = await api.apiRequestWrapper<ApiResponse<RideFeeEconomyReportResult[]>>({
        method: 'get',
        url: queryfilter,
    });

    return res.result
}