import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Icon,
  Select,
  SelectProps,
  Stack,
} from '@chakra-ui/react';

interface FormSelectProps {
  children: React.ReactNode;
  rhfRegister: UseFormRegisterReturn;
  errorState?: FieldError;
  selectProps?: SelectProps;
  formControlProps?: FormControlProps;
  icon?: any;
  label?: string;
  readonly?: boolean;
  placeholder?: string;
}

export default function FormSelect({
  children,
  rhfRegister,
  errorState,
  icon,
  label,
  selectProps,
  formControlProps,
  readonly = false,
  placeholder,
}: FormSelectProps) {
  const id = rhfRegister.name;
  return (
    <FormControl
      isRequired={rhfRegister.required}
      isInvalid={!!errorState}
      isReadOnly={readonly}
      {...formControlProps}
    >
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <Select
        id={id}
        placeholder={placeholder}
        {...selectProps}
        {...rhfRegister}
      >
        {children}
      </Select>

      <FormErrorMessage color="red">
        {errorState && errorState.message}
      </FormErrorMessage>
    </FormControl>
  );
}
