import { Button, FlexProps, Spinner } from "@chakra-ui/react";

export interface ButtonSolidProps {
	title: string;
	onClick?: () => void;
	containerProps?: FlexProps;
	disabled?: boolean;
	type?: "submit" | "button";
	loading?: boolean
}

export const ButtonSolid = ({ title, onClick, disabled, type, loading }: ButtonSolidProps) => {
	const hoverStyles = disabled ? {} : { bg: "hoverBlue" };
	return (
		<Button
			variant="solid"
			bg={disabled ? "#E1E4E4" : "blue"}
			color={disabled ? "#BBBDBD" : "white"}
			fontWeight={400}
			borderRadius={"lg"}
			px={20}
			type={type ? type : "button"}
			_hover={hoverStyles}
			onClick={onClick}
			disabled={disabled || loading}
		>
			{
				loading ? 
					<Spinner />	
					:
					title
			}
		</Button>
	);
};

/*
 * Example usage:
 * <ButtonSolid title="Adicionar"/>
 */
