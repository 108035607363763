import {IPropsChildren} from '../Interfaces/IPropsChildren'
import { useContext} from 'react'
import { UserContext } from '../Context/user.context'
import { Navigate } from 'react-router-dom'

export const PrivateRouter = ({children}: IPropsChildren) => {

    const {token} =  useContext(UserContext)

    if(!token){
        return  <Navigate to={"/login"} />
    }

    return  <div>
                {children}
            </div>
}