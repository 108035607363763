import { Card, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const TextLink = styled(Text)`
  cursor: pointer;
`;

export const CardContent = styled(Card)`
  width: 100%;
  position: absolute !important;
  top: 140px;
  z-index: 9999;
`;

export const DivClose = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1;
  top: 0;
`;
