import { Box, Container, Flex, Heading, Image } from '@chakra-ui/react';
import logo from '../../Assets/logo.png';

export const Home = () => {  
  return (
    <Box>
      <Container display="flex" justifyContent="center">
        <Flex direction="column" marginTop={100} width="max-content">
          <Heading fontSize="57px" color="#6F6F6F">
            Bem Vindo a
          </Heading>
          <Box>
            <Image src={logo} width={340} />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
