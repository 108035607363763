import { extendTheme } from '@chakra-ui/react';
import ButtonStyles from './Components/button';
import SelectStyles from './Components/select';
import TextStyles from './Components/text';
import RangeSliderStyles from './Components/rangeslider';
import CheckboxStyles from './Components/checkbox';
import CardStyle from './Components/card';
import HeadingStyles from './Components/heading';

const theme = extendTheme({
  colors: {
    blue: '#005391',
    orange: '#f96e23',
  },
  breakpoints: {
    sm: '400px',
    md: '600px',
    lg: '900px',
    xl: '1100px',
    '2xl': '1500px',
  },
  components: {
    Button: ButtonStyles,
    Heading: HeadingStyles,
    Text: TextStyles,
    Select: SelectStyles,
    Slider: RangeSliderStyles,
    Checkbox: CheckboxStyles,
    Card: CardStyle,
    Link: TextStyles,
  },
});

export default theme;
