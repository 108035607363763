import { UseFormRegister, UseFormRegisterReturn } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControlProps,
  FormLabel,
  HStack,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderProps,
  SliderThumb,
  SliderTrack,
  Stack,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { LodgingSearchFormValues } from '../../../../Types/lodgings';

interface FormSliderProps {
  rhfRegister: UseFormRegister<LodgingSearchFormValues>;
}

export default function GradeSlider({
  rhfRegister,

  ...props
}: FormSliderProps & SliderProps) {
  const labelStyles = {
    fontSize: 'sm',
    ml: '-3',
    mt: '-3',
    zIndex: 1,
  };
  const [value, setValue] = useState(5);

  const handleSliderChange = (v: number) => {
    setValue(v);
    rhfRegister('filters.grade', { value: v });
    console.log(v);
  };

  return (
    <Slider
      {...props}
      min={1}
      max={5}
      aria-label="slider-ex-1"
      defaultValue={value}
      value={value}
      onChange={(v) => handleSliderChange(v)}
    >
      <Flex>
        <SliderMark value={1} {...labelStyles}>
          <StarIcon color="#FF9529" boxSize={6} />
        </SliderMark>
        <SliderMark value={2} {...labelStyles}>
          <StarIcon color={value >= 2 ? '#FF9529' : '#9c9c9c'} boxSize={6} />
        </SliderMark>
        <SliderMark value={3} {...labelStyles}>
          <StarIcon color={value >= 3 ? '#FF9529' : '#9c9c9c'} boxSize={6} />
        </SliderMark>
        <SliderMark value={4} {...labelStyles}>
          <StarIcon color={value >= 4 ? '#FF9529' : '#9c9c9c'} boxSize={6} />
        </SliderMark>
        <SliderMark value={5} {...labelStyles}>
          <StarIcon color={value >= 5 ? '#FF9529' : '#9c9c9c'} boxSize={6} />
        </SliderMark>
      </Flex>

      <SliderTrack zIndex={-1}>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb zIndex={-1} border="none">
        <StarIcon color="#FF9529" />
      </SliderThumb>
    </Slider>
  );
}
