const SelectStyles = {
  variants: {
    petroAlt: {
      field: {
        borderColor: 'inherit',
        fontSize: '18px',
        px: '0',
      },
      icon: {
        color: 'black',
      },
    },
    petroAltBorder: {
      field: {
        borderColor: 'inherit',
        fontSize: '18px',
        px: '0',
      },
      icon: {
        color: 'black',
      },
    },
  },
  defaultProps: {
    variant: 'petroAlt',
  },
};

export default SelectStyles;
