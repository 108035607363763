import { CompanyResult } from '../../interfaces/company';
import { ApiRepository, ApiResponse } from '../..';

const api = new ApiRepository(2);

export async function GetAllCompanies(UserID: string) {

  const res = await api.apiRequestWrapper<ApiResponse<CompanyResult[]>>({
    method: 'get',
    url:`Company/Administrator?UserID=${UserID}`,
  })
  return res.result.sort((a, b) => a.giveName.trim().localeCompare(b.giveName.trim()));
}
