const ButtonStyles = {
  variants: {
    petroSolid: {
      bg: 'orange',
      color: 'white',
      paddingX: 10,
      _hover: {
        bg: '#F2A479',
      },
    },
    error: {
      bg: 'red.500',
      color: 'white',
      paddingX: 10,
      _hover: {
        bg: 'red.300',
      },
    },
    success: {
      bg: 'green.500',
      color: 'white',
      paddingX: 10,
      _hover: {
        bg: 'green.300',
      },
    },
    secondary: {
      bg: 'transparent',
      border: '1px solid black',
      paddingX: 10,
      color: 'black',
      _hover: {
        bg: 'rgba(0, 0, 0, 0.3)',
      },
    },
    petroAlt: {
      bg: 'white',
      color: 'black',
      paddingX: 10,
      border: '2px',
      borderColor: 'black',
    },
    search: {
      border: '2px',
      borderColor: 'orange',
      color: 'orange',
    },
    remove: {
      color: 'white',
      bg: 'orange',
      _hover: {
        bg: '#F2A479',
      },
    },
    edit: {
      bg: 'white',
      color: 'blue',
    },
    cancel: {
      bg: 'white',
      color: 'orange',
    },
    cancelAlt: {
      bg: 'white',
      color: 'black',
    },
  },
  defaultProps: {
    variant: 'petroSolid',
  },
};

export default ButtonStyles;
