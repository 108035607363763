import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
// import Config from '../Pages/ConfigFlow';
// import FlightPolicies from '../Pages/ConfigFlow/FlightPolicies';
// import FlightPreferences from '../Pages/ConfigFlow/FlightPreferences';
// import FlightRestrictions from '../Pages/ConfigFlow/FlightRestrictions';
// import { Economy } from '../Pages/Driver/Economy';
// import { EconomyFree } from '../Pages/Driver/EconomyFree';
// import { RideEconomy } from '../Pages/Driver/EconomyRide';
// import { RideRateInfo } from '../Pages/Driver/RateInfo';
// import { RideRateInfoUser } from '../Pages/Driver/RateInfoUser';
// import { RideRate } from '../Pages/Driver/Rate';
// import { NoConformity } from '../Pages/Driver/NoConformity';
// import { RacesEstimative } from '../Pages/Driver/RacesEstimative';
import { RacesHeld } from '../Pages/Driver/RacesHeld';
import Dashboard from '../Pages/Dashboard';
// import { RacesProject } from '../Pages/Driver/RacesProject';
// import { RacesUnreconciled } from '../Pages/Driver/RacesUnreconciled';
// import { Warning } from '../Pages/Driver/Warning';
// import { Advances } from '../Pages/Expen/Advances';
// import Fly from '../Pages/Fly';
// import Group from '../Pages/Group';
import { Home } from '../Pages/Home';
import Lodgings from '../Pages/Lodging';
import LodgingDetails from '../Pages/Lodging/LodgingDetails';
// import { Report } from '../Pages/Expen/Report';
// import PendingAccountability from '../Pages/Admin/RH/PendingAccountability';
// import { Expense } from '../Pages/Expen/Expense';
// import { ExpenseReport } from '../Pages/Expen/ExpenseReport';
// import { ExpenseReportKM } from '../Pages/Expen/ExpenseReportKM';
// import { Approval } from '../Pages/Expen/Approval';
// import { FinancialManagement } from '../Pages/Expen/FinancialManagement';
// import Currency from '../Pages/Admin/ConfigExpen/Currency';
// import { ExpenseType } from '../Pages/Admin/ConfigExpen/ExpenseType';
// import { ApprovalFlow } from '../Pages/Admin/ConfigExpen/ApprovalFlow';
// import { ActiveUsers } from '../Pages/Admin/ConfigExpen/ActiveUsers';
// import { CompanyParameters } from '../Pages/Admin/ConfigExpen/CompanyParameters';
import { useContext } from 'react';
import { UserContext } from '../Context/user.context';
import Loading from '../Components/Loading';
import Login from '../Pages/LoginPage'
import { PrivateRouter } from './private.routes';
// import { ConfigUser } from '../Pages/Admin/ConfigUser';
// import { BookedTrip } from '../Pages/Chartered/BookedTrip';
// import { BookedTripFleet } from '../Pages/Fleet/BookingTrip';
// import { HistoryTripFleet } from '../Pages/Fleet/HistoryTrip';
// import GuestUser from '../Pages/Admin/GuestUser';
// import { MadeTrip } from '../Pages/Chartered/MadeTrip';

export const Routers = () => {
  const { loading } = useContext(UserContext);

  if (loading) {
    return(
      <Loading />
    );
  }

  return (
      <Routes >
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<PrivateRouter> <Home  /> </PrivateRouter>} />
        {/* <Route path="/voo" element={<PrivateRouter> <Fly /> </PrivateRouter>} /> */}
        <Route path="/driver/corridas-realizadas" element={<PrivateRouter> <RacesHeld /> </PrivateRouter>} />
        <Route path="/dashboard" element={<PrivateRouter> <Dashboard /> </PrivateRouter>} />
        {/* <Route path="/driver/corridas-por-projeto" element={<PrivateRouter> <RacesProject /> </PrivateRouter>} />
        <Route path="/driver/cotacao" element={<PrivateRouter> <RacesEstimative /> </PrivateRouter>} /> */}
        {/* <Route path="/driver/economia-por-corrida" element={<PrivateRouter> <RideEconomy /> </PrivateRouter>} />
        <Route path="/driver/avaliar-corridas" element={<PrivateRouter> <RideRate/> </PrivateRouter>} /> */}
        {/* <Route path="/driver/avaliar-corridas-info/:receiptID" element={<PrivateRouter> <RideRateInfo /> </PrivateRouter>} /> */}
        {/* <Route path="/driver/avaliar-corridas-info-user" element={<RideRateInfoUser />} /> */}
        {/* <Route path="/driver/corridas-nao-reconciliadas-pendentes" element={<PrivateRouter> <RacesUnreconciled /> </PrivateRouter>}/> */}
        {/* <Route path="/driver/economia-por-transacao" element={<PrivateRouter> <Economy /> </PrivateRouter>} />
        <Route path="/driver/economias-com-comissionamentos" element={<PrivateRouter> <EconomyFree /> </PrivateRouter>}/>
        <Route path="/driver/relatorio-de-nao-conformidades" element={<PrivateRouter> <NoConformity /> </PrivateRouter>}/>
        <Route path="/driver/warning" element={<PrivateRouter> <Warning /> </PrivateRouter>} /> */}

        <Route path="/hospedagem" element={<PrivateRouter> <Lodgings /> </PrivateRouter>} />
        <Route path="/hospedagem/:lodgindID" element={<PrivateRouter> <LodgingDetails /> </PrivateRouter>} />
        {/* <Route path="/expen/adiantamentos" element={<PrivateRouter> <Advances /> </PrivateRouter>} />
        <Route path="/expen/relatorio" element={<PrivateRouter> <Report /> </PrivateRouter>} />
        <Route path="/expen/despesa" element={<PrivateRouter> <Expense /> </PrivateRouter>} />
        <Route path="/expen/relatorio-de-despesa" element={<PrivateRouter> <ExpenseReport /> </PrivateRouter>} />
        <Route path="/expen/relatorio-de-despesa-km" element={<PrivateRouter> <ExpenseReportKM /> </PrivateRouter>} />
        <Route path="/expen/aprovacao" element={<PrivateRouter> <Approval /> </PrivateRouter>} />
        <Route path="/expen/administracao-financeira" element={<PrivateRouter> <FinancialManagement /> </PrivateRouter>} />
        
        <Route path="/fretado/viagens-reservadas" element={<PrivateRouter> <BookedTrip /> </PrivateRouter>} />
        <Route path="/fretado/viagens-realizadas" element={<PrivateRouter> <MadeTrip /> </PrivateRouter>} />

        <Route path="/frota/viagens-reservadas" element={<PrivateRouter> <BookedTripFleet /> </PrivateRouter>} />
        <Route path="/frota/viagens-realizadas" element={<PrivateRouter> <HistoryTripFleet /> </PrivateRouter>} /> */}
        
        {/* <Route path='/config' element={<PrivateRouter> <Config /> </PrivateRouter>}> */}
          {/* <Route path='politicas-de-voo' element={<PrivateRouter> <FlightPolicies /> </PrivateRouter>} />
          <Route path='restricoes-de-voo' element={<PrivateRouter> <FlightRestrictions /> </PrivateRouter>} />
          <Route path='preferencias-de-voo' element={<PrivateRouter> <FlightPreferences /> </PrivateRouter>} /> */}
        {/* </Route> */}
        {/* <Route path='/configurar-conta' element={<PrivateRouter> <ConfigUser /> </PrivateRouter>} />

        <Route path='/adm/pendente-de-prestacao-de-contas' element={<PrivateRouter> <PendingAccountability /> </PrivateRouter>}/>
        <Route path='/adm/moeda' element={<PrivateRouter> <Currency /> </PrivateRouter>}/>
        <Route path='/adm/tipo-de-despesa' element={<PrivateRouter> <ExpenseType/> </PrivateRouter>}/>
        <Route path='/adm/fluxo-de-aprovacao' element={<PrivateRouter> <ApprovalFlow/> </PrivateRouter>}/>
        <Route path='/adm/usuarios-ativos' element={<PrivateRouter> <ActiveUsers/> </PrivateRouter>}/>
        <Route path='/adm/parametros-empresa' element={<PrivateRouter> <CompanyParameters/> </PrivateRouter>}/>
        <Route path='/adm/usuario-convidado' element={<PrivateRouter> <GuestUser/> </PrivateRouter>}/>
        
        <Route path='grupo' element={<PrivateRouter> <Group /> </PrivateRouter>} /> */}
 
      </Routes>
  );
};
