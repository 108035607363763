import { ApiRepository, ApiResponse } from "../../..";
import { ReportNonconformityRequest, ReportNonconformityResult } from "../../../interfaces/driver/receipt";

export async function GetNonconformity(req: ReportNonconformityRequest) {
    const api = new ApiRepository(3);

    let queryfilter = "Reports/Nonconformity?companyID=" + req.CompanyID
                                        + "&startDate=" + req.StartDate
                                        + "&endDate=" + req.EndDate
                                        + "&warningTypeID=51"

        if (req.CostCenterID != 0)
            queryfilter += "&CostCenterID=" + req.CostCenterID;
        //if (req.sourceID != 0)
        //    queryfilter += "&SourceID=" + req.sourceID;
        if (req.PaymentMethod != "0")
            queryfilter += "&PaymentMethod=" + req.PaymentMethod;

    const res = await api.apiRequestWrapper<ApiResponse<ReportNonconformityResult[]>>({
        method: 'get',
        url: queryfilter,
    });

return res.result
}