const HeadingStyles = {
    variants: {
        pageTitle: {
            fontSize: '30px',
            color: 'black',
            as: 'b',
            },
        cardTitle: {
            fontSize: '20px',
            color: 'black',
            },
        cardSubtitle: {
            fontSize: '15px',
            color: 'black',
            },
        cardHintSubititle: {
            fontSize: '15px',
            color: '#9c9c9c',
            },
    }
}

export default HeadingStyles;