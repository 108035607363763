import {
  Card,
  CardBody,
  Heading,
  Stack,
  Box,
  StackDivider,
  Text,
  UnorderedList,
  ListItem,
  CardProps,
} from '@chakra-ui/react';

export default function PolicyCard({ ...props }: CardProps) {
  return (
    <Card {...props}>
      <CardBody>
        <Stack direction={'column'} divider={<StackDivider />} spacing={1}>
          <Heading variant={'cardTitle'}>Sua política de voos</Heading>
          <Box pt={'5px'}>
            <Heading variant={'cardHintSubititle'}>
              Preço máximo permitido
            </Heading>
            <UnorderedList pl={2}>
              <ListItem>
                <Text variant={'hintInfo'}>Sem limite</Text>
              </ListItem>
            </UnorderedList>
            <Heading variant={'cardHintSubititle'} pt={'10px'}>
              Quando reservar
            </Heading>
            <UnorderedList pl={2}>
              <ListItem>
                <Text variant={'hintInfo'}>5 dias de antecedência</Text>
              </ListItem>
            </UnorderedList>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
}
