import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Stack,
} from '@chakra-ui/react';

const dateInputStyle = {
  '::-webkit-calendar-picker-indicator': {
    background: 'transparent',
    bottom: 0,
    color: 'transparent',
    cursor: 'pointer',
    height: 'auto',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 'auto',
  },
};

interface FormInputProps {
  rhfRegister: UseFormRegisterReturn;
  errorState?: any;
  inputProps?: InputProps;
  formControlProps?: FormControlProps;
  icon?: any;
  label?: string;
  readonly?: boolean;
}

export default function FormInput({
  rhfRegister,
  errorState,
  icon,
  label,
  inputProps,
  formControlProps,
  readonly = false,
}: FormInputProps) {
  const id = rhfRegister.name;
  return (
    <FormControl
      id={id}
      isRequired={rhfRegister.required}
      isInvalid={!!errorState}
      isReadOnly={readonly}
      {...formControlProps}
    >
      {label && (
        <FormLabel color={'white'} htmlFor={id}>
          {label}
        </FormLabel>
      )}
      <Stack direction="row" alignItems="center">
        <InputGroup>
          {icon && (
            <InputLeftElement>
              <Icon as={icon} boxSize={5} color="gray" />
            </InputLeftElement>
          )}
          <Input
            _placeholder={{ color: 'gray' }}
            sx={dateInputStyle}
            {...inputProps}
            {...rhfRegister}
          />
        </InputGroup>
      </Stack>
      <FormErrorMessage color="red">
        {errorState && errorState.message}
      </FormErrorMessage>
    </FormControl>
  );
}
