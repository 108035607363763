
import { 
    createContext,
    useEffect, 
    useState 
} 
from "react";

import {getToken, LoginCheck} from "../Api/services/auth";
import { GetUserByID } from "../Api/services/user";
import parseJwt from "../utils/parseJwt";
import { jwtDecode } from "jwt-decode";

interface IUserContext {
    token: string,
    loading: boolean,
    userID: string,
    login: (username: string , password: string) => Promise<number>,
    logout: () => void

}

export const UserContext = createContext<IUserContext>({token: '', userID: '', loading: true} as IUserContext);

export default function UserContextProvider({children} : any) {
    const [token, setToken] = useState('');
    const [userID, setUserID] = useState<string>('');
    const [loading, setLoading] = useState(true);
    
    const getUserId = (token: string) => {
        const { sub } = parseJwt(token);        
      
        return sub      
    }

    const login = async ( username: string , password: string) => {

        const res = await LoginCheck(username, password)
        // console.log(res)
        // const decoded = jwtDecode(res.access_token);

        // console.log(decoded);
        if(res.access_token){   
            const decoded: any = jwtDecode(res.access_token)

            if(decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].includes('provideradministrator')){
                setToken(res.access_token)
                setUserID(getUserId(res.access_token))
                console.log(res)
    
                localStorage.setItem('token', JSON.stringify(res))
                return 200
            }
            
            return 500
        }

        return 500
    }

    const logout = async () => {

        setToken("")
        setUserID("")
        
        localStorage.removeItem('token')    
    }

    const loadingDataUser = async () => {
        const storedToken = localStorage.getItem('token')

        if(!storedToken){     
            setLoading(false)
            return
        }
        
        setToken(JSON.parse(storedToken).access_token)
        setUserID(getUserId(JSON.parse(storedToken).access_token))
        setLoading(false)
    }

    useEffect(() => {
        loadingDataUser()
    }, [])

    return ( 
    <UserContext.Provider value={{token, userID, login , logout,loading}}>
        {children}
    </UserContext.Provider>
    );
}