import { IFilter } from "../../../../../Interfaces/IFilter";
import { ReportNonconformityRequest, ReportNonconformityResult } from "../../../../interfaces/driver/receipt";
import { GetAllCompanies } from "../../../company";
import { GetNonconformity } from "../../noConformity";

//REQUISIÇÃO PARA A TELA RELATÓRIO DE NÃO CONFORMIDADES
export async function ListNonconformity(userID: any , filter?: IFilter) {
 
    let finalreport: ReportNonconformityResult[] = [];
    
    const req: ReportNonconformityRequest = {
        CompanyID: undefined,
        CostCenterID: filter?.costCenter,
        Companies: filter?.companies,
        SourceID: undefined,
        StartDate: filter?.periodStart,
        EndDate: filter?.periodEnd,
        WarningTypeID: undefined,
        PaymentMethod: filter?.beneblex,
        //SourceID: filter?.sourceID   
        //WarningType: filter?.warningType   
    }

    if (req.EndDate != null){
      req.EndDate += " 23:59:59"
    };

        if (req.Companies?.some(item => item === "0") )
        {    
              const companies = await GetAllCompanies(userID);      

              for (const company of companies || []) {
                req.CompanyID = company.companyID
    
                const resReport = await GetNonconformity(req);
                finalreport = [...finalreport, ...resReport];
              } 
        }
        else
        {
            for (const company of req.Companies || []) {
              req.CompanyID = company;
                  
              const resReport = await GetNonconformity(req);
              finalreport = [...finalreport, ...resReport];        
          }
        }

    return finalreport
}