import { Button, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { HotelAccomodations } from '../../../Api/interfaces/hotel';

export const useHotelAccomodationsColumns = () => {
  const columnHelper = createColumnHelper<HotelAccomodations>();

  const columns = [
    columnHelper.accessor('type', {
      header: 'Tipo de acomodação',
      cell: (info) => <Text>{info.getValue()}</Text>,
    }),

    columnHelper.accessor('capacity', {
      header: 'Qtd de hóspedes',
      cell: (info) => <Text>{info.getValue()}</Text>,
    }),

    columnHelper.accessor('rental', {
      header: 'Preço / diária',
      cell: (info) => <Text>{info.getValue()}</Text>,
    }),

    columnHelper.display({
      header: 'Reservar',
      id: 'actions',
      cell: (info) => {
        return <Button>Reservar</Button>;
      },
    }),
  ];

  return columns;
};
