const RangeSliderStyles = {
    variants: {
      petroSolid: {
        container: {},
        track: {},
        thumb: {
          boxSize: '20px',
          borderColor: 'black'
        },
        filledTrack: {
          bg: 'orange',
        },
      },
    },
    defaultProps: {
        variant: 'petroSolid',
      },
  }

export default RangeSliderStyles;