import {
  Card,
  CardBody,
  CardProps,
  Divider,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

export interface HotelConvenienceCardProps {
  conveniences: string[];
  checkIn: string;
  checkOut: string;
}

export default function HotelConvenienceCard({
  conveniences,
  checkIn,
  checkOut,
  ...props
}: HotelConvenienceCardProps & CardProps) {
  return (
    <Card {...props}>
      <CardBody>
        <Text textAlign="center">
          <b>Check-in:</b> {checkIn} - <b>Check-out:</b> {checkOut}
        </Text>

        <Heading variant="cardTitle">Comodidades</Heading>
        <Divider />
        <SimpleGrid columns={2} mt={6} gap={3}>
          {conveniences.map((c) => {
            return <Text key={c}>{c}</Text>;
          })}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
}
