import { useContext } from "react"
import { ListRide } from "../Api/services/driver/ListRide"
import { ListDriverSaving } from "../Api/services/driver/pages/Economy"
import { ListRideFeeEconomy } from "../Api/services/driver/pages/EconomyFree"
import { ListRideEconomy } from "../Api/services/driver/pages/EconomyRide"
import { ListNonconformity } from "../Api/services/driver/pages/NoConformity"
import { ListRideEstimative } from "../Api/services/driver/pages/RacesEstimative"
import getPaymentDriver from "../Api/services/driver/pages/RacesHeld"
import { ListProject } from "../Api/services/driver/pages/RacesProject"
import { ListRideUnreconciled } from "../Api/services/driver/pages/RacesUnreconciled"
import { GetAtestContest } from "../Api/services/driver/pages/Rate"
import { ListWarnings } from "../Api/services/driver/pages/Warning"
import { UserContext } from "../Context/user.context"
import { IFilter } from "../Interfaces/IFilter"
import { GetDashboard } from "../Api/services/driver/receipt"

export const useService = () => {    
    const {userID} = useContext(UserContext)

    const getPaymentDriverService = async (filter?: IFilter) => {
        const res = await getPaymentDriver(userID, filter)
                
        return res
    }

    const ListRideService = async (filter?: IFilter) => {
        const res = await ListRide(userID, filter)

        return res
    }

    const ListProjectService = async (filter?: IFilter) => {
        const res = await ListProject(userID, filter)
            
        return res
    }

    const ListRideUnreconciledService = async (filter?: IFilter) => {
        const res = await ListRideUnreconciled(userID, filter)
            
        return res
    }

    const ListRideFeeEconomyService = async (filter?: IFilter) => {
        const res = await ListRideFeeEconomy(userID, filter)
            
        return res
    }

    const ListDriverSavingService = async (filter?: IFilter) => {
        const res = await ListDriverSaving(userID, filter)
            
        return res
    }

    const ListRideEconomyService = async (filter?: IFilter) => {
        const res = await ListRideEconomy(userID, filter)
            
        return res
    }

    const ListNonconformityService = async (filter?: IFilter) => {
        const res = await ListNonconformity(userID, filter)
            
        return res
    }

    const ListRideEstimativeService = async (filter?: IFilter) => {
        const res = await ListRideEstimative(userID, filter)
            
        return res
    }

    const ListWarningsService = async (filter?: IFilter) => {
        const res = await ListWarnings(userID, filter)
            
        return res
    }

    const GetAtestContestService = async (filter?: IFilter) => {
        const res = await GetAtestContest(userID, filter)
            
        return res
    }

    const GetDashboardService = async(filter?: IFilter) => {
        const res = await GetDashboard(filter)
            
        return res
    }

    return {
        getPaymentDriverService,
        ListProjectService,
        ListRideUnreconciledService,
        ListRideFeeEconomyService,
        ListDriverSavingService,
        ListRideEconomyService,
        ListNonconformityService,
        ListRideEstimativeService,
        ListWarningsService,
        GetAtestContestService,
        ListRideService,
        GetDashboardService
    }
}