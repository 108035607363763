import { ApiRepository } from "../.."

interface IAuthResult {
    data: {
        access_token: string,
        expires_in: number,
        token_type: "Bearer"
    }
}

interface IAuth {
    clientID: number,
    clientSecret: string,
    username: string,
    password: string,
    scope: string,
}

export  async function getToken(){
    const url = 'connect/token';
    const clientId = 'portalv2';
    const clientSecret = 'secret';
    const scope = 'wExpoPublicAPI wExpoExpensesPublicAPI';
    const grantType = 'password'
    const username = 'andre.insardi@wexp.com.br'
    const password = '2409000'

    const api = new ApiRepository(1)
    const res: any = await api.apiRequestWrapper({
        method: 'POST',
        url,
        data: {
            grant_type: grantType,
            client_id: clientId,
            client_secret: clientSecret,
            scope: scope,
            username,
            password
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })    
    return res
}


export  async function LoginCheck(usuario: string , senha: string){
    const url = 'connect/token';
    const clientId = 'portalv2';
    const clientSecret = 'secret';
    const scope = 'wExpoPublicAPI wExpoExpensesPublicAPI';
    const grantType = 'password'
    const username = usuario
    const password = senha

    const api = new ApiRepository(1)
    const res: any = await api.apiRequestWrapper({
        method: 'POST',
        url,
        data: {
            grant_type: grantType,
            client_id: clientId,
            client_secret: clientSecret,
            scope: scope,
            username,
            password
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
    
    return res
}