import { IFilter } from "../../../../../Interfaces/IFilter"
import { PaymentDriverResult, ResultDrive } from "../../../../interfaces/driver/paymets";
import { GetReport } from "../../ride";
import { ReportRideRequest, RideReportResult } from "../../../../interfaces/driver/ride";
import { GetAllCompanies } from "../../../company";
import { Filter } from "../../receipt";
import { ReceiptResult, ReportBasicFilterRequest } from "../../../../interfaces/driver/receipt";
import { ListRide } from "../../ListRide";


//REQUISIÇÃO PARA A TELA CORRIDAS REALIZADAS
export default async function getPaymentDriver(userID: string, filter?: IFilter){
  
  let report:RideReportResult[] = [];
  let report2:ReceiptResult[] = [];
  let finalReport: PaymentDriverResult[] = [];
  let lista: PaymentDriverResult;

  const req: ReportRideRequest = {
      CompanyID: undefined ,
      CostCenterID: filter?.costCenter,
      Companies: filter?.companies,
      Company:undefined ,
      StartDate: filter?.periodStart,
      EndDate: filter?.periodEnd,
      corridaRealizada: filter?.races_held,
      PaymentMethod: filter?.beneblex,
      providerid: filter?.providerid  
  }

  const req2: ReportBasicFilterRequest = {
      UserID: userID,
      CompanyID:undefined,
      Companies:filter?.companies,
      CostCenterID: filter?.costCenter,
      StartDate: filter?.periodStart,
      EndDate:  filter?.periodEnd,
      Productive: undefined,
      PaymentMethod: filter?.beneblex,
      providerid: filter?.providerid,
      ReceiptStatusID:undefined,
      TransactionID: undefined
  }
  
  if (req.EndDate != null){
    req.EndDate += " 23:59:59";
  }

  if (req2.EndDate != null){
    req2.EndDate += " 23:59:59";
  }
        
  if (req.Companies?.some(item => item === "0") ){    
          const companies = await GetAllCompanies(userID);      

          for (const company of companies || []) {
            req.CompanyID = company.companyID
            const resReport = await GetReport(req);
            report = [...report, ...resReport] 
           
              if (req.corridaRealizada != "1"){

                req2.ReceiptStatusID = 3;
                let resReport2 = await Filter(req2);
                report2 = [...report2, ...resReport2];

                req2.ReceiptStatusID = 13;
                resReport2 = await Filter(req2);
                report2 = [...report2, ...resReport2];
              }
          }        
    }
  else{   
        for (const company of req.Companies || []) {
            req.CompanyID = company;
            const resReport = await GetReport(req);
            report = [...report, ...resReport] 
       
            if (req.corridaRealizada != "1"){
              req2.ReceiptStatusID = 3;
              let resReport2 = await Filter(req2);
              report2 = [...report2, ...resReport2];

              req2.ReceiptStatusID = 13;
              resReport2 = await Filter(req2);
              report2 = [...report2, ...resReport2];
            }
        }       
    }    

  for (const reports of report || []){    
    lista = 
      {
          transactionID: reports.transactionID,
          rideID: reports.rideID,                                   
          company: reports.company,
          name: reports.name,
          phone: reports.phone,
          create: reports.create,
          provider: reports.provider,
          estimated: reports.estimated,
          price: reports.price,
          distancy: reports.distancy,
          duration: reports.duration,
          totalUsers: reports.totalUsers,
          category: reports.category,
          startAddress: reports.starAddress,
          startLat: reports.startLat,
          startLng: reports.startLng,
          endAddress: reports.endAddress,
          endLat:reports.endLat,
          endLng: reports.endLng,
          driver: reports.driver,
          car: reports.car,
          plate: reports.plate,
      }
      finalReport =[...finalReport, lista];  
  }

  for (const reports of report2 || []){    
    lista = 
      {

        transactionID: reports.transactionID,
        company: reports.company,
        name: reports.name,
        phone: reports.phone,
        create: reports.create,
        provider: reports.provider,
        endAddress: reports.endAddress,
        duration: reports.duration,
        product: reports.product,
        price: reports.price,
        productID: reports.productID,
        receiptStatusID: reports.receiptStatusID,
        companyID: reports.companyID,
        costCenter: reports.costCenter,
        supervisionGroupId: reports.supervisionGroupId,
        supervisionGroup: reports.supervisionGroup,
        currency: reports.currency,
        distancy: reports.distancy,
        startAddress: reports.startAddress,
        startDate: reports.startDate,
        startLat: reports.startLat,
        startLng: reports.startLng,
        endDate: reports.endDate,
        endLat: reports.endLat,
        endLng: reports.endLng,
        performedType: "Por Fora"
      }
      finalReport =[...finalReport, lista];     
  }
   
  return finalReport
}