import { Center, ChakraProvider, Image } from '@chakra-ui/react';
import { Routers } from './Routes/index.routes';
import Header from './Components/Header';
import theme from './theme';
import UserContextProvider from './Context/user.context';
import { BrowserRouter, useLocation } from 'react-router-dom';
import Logo from '../src/Assets/logo.png';

export const App = () => {
  const location = useLocation();

  return (
    <UserContextProvider>
      <ChakraProvider theme={theme}>
        {location.pathname !== '/driver/avaliar-corridas-info-user' &&
          location.pathname !== '/login' && <Header />}
        <Routers />
        {location.pathname !== '/' && location.pathname !== '/login' && (
          <Center padding={12}>
            <Image w={24} src={Logo} />
          </Center>
        )}
      </ChakraProvider>
    </UserContextProvider>
  );
};
