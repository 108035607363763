import React, { useEffect, useState, CSSProperties } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { IFilter } from "../../Interfaces/IFilter";
import { useService } from "../../Hooks/useService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  BarController,
  LineController,
  Title,
  Tooltip,
  Legend
);

const Dashboard: React.FC = () => {
  const { GetDashboardService } = useService();
  const [dashboardData, setDashboardData] = useState<any>(null);

  const GetDashboard = async (filter?: IFilter) => {
    try {
      const res: any = await GetDashboardService(filter);
      console.log('Resposta bruta da API:', res);

      if (res && res.result && res.result.groupedByMonth) {
        console.log('Dados processados:', res.result);
        setDashboardData(res.result);
      } else {
        console.warn('Nenhum dado encontrado na resposta da API.');
        setDashboardData({});
      }
    } catch (error) {
      console.error("Erro ao buscar dados do dashboard:", error);
      setDashboardData({});
    }
  };

  useEffect(() => {
    GetDashboard();
  }, []);

  const roundToTwo = (num: number) => {
    return isNaN(num) ? 0 : Math.round(num * 100) / 100;
  };

  const translateMonth = (month: string) => {
    const months: { [key: string]: string } = {
      January: 'Janeiro',
      February: 'Fevereiro',
      March: 'Março',
      April: 'Abril',
      May: 'Maio',
      June: 'Junho',
      July: 'Julho',
      August: 'Agosto',
      September: 'Setembro',
      October: 'Outubro',
      November: 'Novembro',
      December: 'Dezembro'
    };
    return months[month] || month;
  };

  const calculateAvgPricePerProvider = (receipts: any[]) => {
    const totalPrice = receipts.reduce((acc: number, receipt: any) => acc + receipt.price, 0);
    const count = receipts.length;
    return count > 0 ? roundToTwo(totalPrice / count) : 0;
  };

  const calculateAvgRidePrice = (rides: any[]) => {
    const totalPrice = rides.reduce((acc: number, ride: any) => acc + ride.price, 0);
    const count = rides.length;
    return count > 0 ? roundToTwo(totalPrice / count) : 0;
  };

  const data = {
    labels: dashboardData
      ? dashboardData.groupedByMonth.map((monthData: any) => translateMonth(monthData.month))
      : [],
    datasets: [
      {
        type: "bar" as const,
        label: "Preço Médio Cotação Vencedora",
        data: dashboardData
          ? dashboardData.groupedByMonth.map(
              (monthData: any) => {
                const totalProviderPrice = calculateAvgPricePerProvider(monthData.receipts);
                console.log(`Total Provider Price for ${translateMonth(monthData.month)}: ${totalProviderPrice}`);
                return totalProviderPrice;
              }
            )
          : [],
        backgroundColor: "rgba(0, 0, 255, 0.5)",
        borderColor: "blue",
        borderWidth: 1,
      },
      {
        type: "line" as const,
        label: "Preço Médio Cotações",
        data: dashboardData
          ? dashboardData.groupedByMonth.map(
              (monthData: any) => {
                const rides = monthData.receipts.filter((receipt: any) => receipt.rideID !== null && receipt.rideID !== undefined);
                console.log(rides)
                const totalRidePrice = calculateAvgRidePrice(rides);
                console.log(`Total Ride Price for ${translateMonth(monthData.month)}: ${totalRidePrice}`);
                return totalRidePrice;
              }
            )
          : [],
        fill: false,
        borderColor: "orange",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          font: {
            size: 10,
          },
        },
      },
      title: {
        display: true,
        text: "Preço Médio Cotação Vencedora e Preço Médio Cotações por Mês",
        font: {
          size: 14,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 60,
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
      },
    },
  };

  if (!dashboardData || Object.keys(dashboardData).length === 0) {
    return <div>Carregando...</div>;
  }

  const boxStyle: CSSProperties = {
    boxShadow: "0px 0px 5px 0px rgba(174, 174, 174, 0.6)",
    borderRadius: "5px",
    padding: "16px",
    marginRight: "16px",
    textAlign: "center" as "center",
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "20px",
        }}
      >
        <div style={boxStyle}>
          <h1>
            <b>{dashboardData.calculations.totalRides}</b>
          </h1>
          <p>Cotações Realizadas</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>{dashboardData.calculations.receiptCount}</b>
          </h1>
          <p>Corridas Realizadas</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>{roundToTwo(dashboardData.calculations.receiptCountPercentage)}%</b>
          </h1>
          <p>Percentual de Corridas Realizadas x Cotadas</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>
              {dashboardData.calculations.avgPricePerProvider && dashboardData.calculations.avgPricePerProvider.length > 0 
                ? `R$ ${roundToTwo(dashboardData.calculations.avgPricePerProvider[0].averagePrice)}`
                : "R$ -"
              }
            </b>
          </h1>
          <p>Preço Médio Cotação Vencedora</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>R$ {roundToTwo(dashboardData.calculations.avgRidePrice)}</b>
          </h1>
          <p>Preço Médio Cotações</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>
              {dashboardData.calculations.priceDifferencePercentage && dashboardData.calculations.priceDifferencePercentage.length > 0 
                ? `${roundToTwo(dashboardData.calculations.priceDifferencePercentage[0].priceDifferencePercentage)}%`
                : "-"
              }
            </b>
          </h1>
          <p>Variação Percentual do Preço</p>
        </div>
      </div>
      <div style={{ width: "70%", height: "250px", margin: "0 auto" }}>
        <Chart type="bar" data={data} options={options} />
      </div>
    </div>
  );
};

export default Dashboard;
