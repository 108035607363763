import { ApiRepository, ApiResponse } from "../../..";
import { ReportRideRequest, RideReportResult } from "../../../interfaces/driver/ride";

export async function GetReport(req: ReportRideRequest) {
    const api = new ApiRepository(3);

    let queryfilter =`Reports/Ride?companyID=${req.CompanyID}&startDate=${req.StartDate}&endDate=${req.EndDate}&corridaRealizada=${req.corridaRealizada}&providerid=${req.providerid}`;

    if (req.CostCenterID != 0 && req.CostCenterID != undefined) {
        queryfilter += `&CostCenterID=${req.CostCenterID}`;
      }
      
    if (req.PaymentMethod != "0" && req.PaymentMethod !=undefined){
        queryfilter += `&PaymentMethod=${req.PaymentMethod}`;
    }
     
    const res = await api.apiRequestWrapper<ApiResponse<RideReportResult[]>>({
        method: 'get',
        url: queryfilter,
    });
    
    return res.result
}