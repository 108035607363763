import { ApiRepository, ApiResponse } from "../../../..";
import { IFilter } from "../../../../../Interfaces/IFilter";
import { ProjectReportResult } from "../../../../interfaces/driver/project";
import { ReportBasicFilterRequest } from "../../../../interfaces/driver/receipt";
import { GetAllCompanies } from "../../../company";
import { GetProject } from "../../project";


//REQUISIÇÃO PARA A TELA CORRIDAS POR PROJETO 
export async function ListProject(userID: any,filter?: IFilter) {
  
  let finalreport: ProjectReportResult[] = [];

    const req: ReportBasicFilterRequest = {
        UserID: userID,
        CompanyID:undefined,
        Companies:filter?.companies,
        CostCenterID: filter?.costCenter,
        StartDate: filter?.periodStart,
        EndDate:  filter?.periodEnd,
        Productive: undefined,
        PaymentMethod: filter?.beneblex,
        providerid: filter?.provider,
        ReceiptStatusID:undefined,
        TransactionID: undefined
    }

    if (req.EndDate != null){
      req.EndDate += " 23:59:59"; 
    }      

    if (req.Companies?.some(item => item === "0")){    
          const companies = await GetAllCompanies(userID);  

          for (const company of companies || []) {
            req.CompanyID = company.companyID
            const resReport = await GetProject(req);
            finalreport = [...finalreport, ...resReport] 
          }  
    }else{      

          for (const company of req.Companies || []) {
            req.CompanyID = company;
            const resReport = await GetProject(req);
            finalreport = [...finalreport, ...resReport];
          }         
      }

    return finalreport
}