import { ApiRepository, ApiResponse } from "../../../..";
import { IFilter } from "../../../../../Interfaces/IFilter";
import { ProjectReportResult } from "../../../../interfaces/driver/project";

//REQUISIÇÃO PARA A TELA ECONOMIA POR TRANSAÇÃO 
export async function ListDriverSaving(userID: any, filter?: IFilter) {
    
    let api = new ApiRepository(3);
  
    const res = await api.apiRequestWrapper<ApiResponse<number>>({
        method: 'get',
        url: `Expense/Rides/${filter?.companies}?StartDate=${filter?.periodStart}&EndDate=${filter?.periodEnd} `,
    });

    api = new ApiRepository(2);

    const res2 = await api.apiRequestWrapper<ApiResponse<ProjectReportResult[]>>({
        method: 'get',
        url: `ReportSaving?RideID=${res.result}`,
    });

    return res2.result
}