import {
  Box,
  Card,
  CardBody,
  Heading,
  HStack,
  Stack,
  Text,
  Image,
  Icon,
  Divider,
  Button,
  Spacer,
  Flex,
} from '@chakra-ui/react';

import { StarIcon } from '@chakra-ui/icons';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import GradeView from '../../GradeView';

export interface HotelCardProps {
  hotelID: string;
  hotelName: string;
  hotelGrade: number;
  downtownDistance: string;
  dailyPrice: string;
}

export default function HotelCard({
  dailyPrice,
  downtownDistance,
  hotelGrade,
  hotelID,
  hotelName,
}: HotelCardProps) {
  const navigate = useNavigate();
  return (
    <Card mb={3}>
      <CardBody>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={{ base: 3, md: 6 }}
        >
          <Image
            maxH={{ base: 'auto', md: 36 }}
            src="https://media-cdn.tripadvisor.com/media/photo-s/22/25/ce/ea/kingsford-hotel-manila.jpg"
          />
          <Stack>
            <Heading variant="cardTitle">{hotelName}</Heading>
            <GradeView hotelGrade={hotelGrade} />
            <HStack>
              <Icon as={FaMapMarkerAlt} />
              <Text>{downtownDistance} do centro</Text>
            </HStack>
          </Stack>
          <Spacer />

          <Stack>
            <Box>
              <Text variant="title2">R$ {dailyPrice}</Text>
              <Text mt={-2}>a diária</Text>
            </Box>
            <Spacer />
            <Button onClick={() => navigate(`/hospedagem/${hotelID}`)}>
              Veja mais
            </Button>
          </Stack>
        </Flex>
      </CardBody>
    </Card>
  );
}
