import { HotelCardProps } from '../../Components/Card/HotelCard';

export const lodgingData: HotelCardProps[] = [
  {
    hotelID: '111aa',
    hotelName: 'Hotelzão',
    hotelGrade: 5,
    dailyPrice: '200,00',
    downtownDistance: '9km',
  },
  {
    hotelID: '111ab',
    hotelName: 'Hotelzin',
    hotelGrade: 5,
    dailyPrice: '158,00',
    downtownDistance: '12km',
  },
  {
    hotelID: '111ac',
    hotelName: 'Aquele hotel',
    hotelGrade: 4,
    dailyPrice: '136,00',
    downtownDistance: '1km',
  },
  {
    hotelID: '111ad',
    hotelName: 'Oraqui oralá',
    hotelGrade: 2,
    dailyPrice: '62,00',
    downtownDistance: '6km',
  },
  {
    hotelID: '111ae',
    hotelName: 'Cade o hotel',
    hotelGrade: 3,
    dailyPrice: '53,00',
    downtownDistance: '22km',
  },
];
