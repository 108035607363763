import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios';
import { useContext } from 'react';
import { UserContext } from '../Context/user.context';

export interface ApiRequestWrapperResponse<T = any> {
  data?: T;
  error?: AxiosError;
  success: boolean;
}

export interface ApiRequestWrapperArgs {
  method: AxiosRequestConfig['method'];
  url: string;
  data?: object;
  headers?: RawAxiosRequestHeaders;
  timeout?: AxiosRequestConfig['timeout'];
  params?: AxiosRequestConfig['params'];
}

export interface ApiResponse<T> {
  errors: any;
  message: string;
  result: T;
  statusCode: number;
}

export class ApiRepository {
  apiInstance: AxiosInstance;
  constructor(APIindex: number) {
    let api;
    switch(APIindex){
      case (1): 
                  api = Axios.create({
                  baseURL:
                    process.env.NODE_ENV === 'development'
                     ? `https://wexp-authserver-development.azurewebsites.net/` // dev
                     : `https://wexp-authserver.azurewebsites.net/`, // prod
                  })
      break;

      default:
      case (2): 
                  api = Axios.create({
                  baseURL:
                    process.env.NODE_ENV === 'development'
                    ? `https://wexp-wexpopublicapi-development.azurewebsites.net/api/` // dev
                    : `https://wexp-wexpopublicapi.azurewebsites.net/api/`, // prod
                  })
      break;
      
      case (3):  
                  api = Axios.create({
                  baseURL:
                    process.env.NODE_ENV === 'development'
                      ? `https://wexp-wexpoexpensespublicapi-development.azurewebsites.net/api/` // dev
                      : `https://wexp-wexpoexpensespublicapi.azurewebsites.net/api/`, // prod
                  })
      break;
    }   

    this.apiInstance = api;
  }

//   isAuth
//      ? (api = Axios.create({
//          baseURL:
//            process.env.NODE_ENV === 'development'
//              ? `https://wexp-authserver-development.azurewebsites.net/` // dev
//              : `https://wexp-authserver-development.azurewebsites.net/`, // prod
//        }))
//      : (api = Axios.create({
//          baseURL:
//            process.env.NODE_ENV === 'development'
//             ? `https://wexp-wexpopublicapi-development.azurewebsites.net/api/` // dev
//              : `https://wexp-wexpopublicapi-development.azurewebsites.net/api/`, // prod
//        }));
  

   public getAccessToken(): string | null {
    const storage = localStorage.getItem('token')

    if(!storage){
      return null;
    }

    return JSON.parse(storage).access_token;
  }

  public async apiRequestWrapper<T>(args: ApiRequestWrapperArgs): Promise<T> {
    try {
      const accessToken = this.getAccessToken();
      const config = {
        method: args.method,
        url: args.url,
        data: args.data,
        timeout: args?.timeout ?? 30 * 1000, // 30s timeout
        params: args?.params,
        headers: accessToken
          ? {
              Authorization: `Bearer ${accessToken}`,
              ...(args.headers || {}),
            }
          : { ...(args.headers || {}) },
      };

      const res = await this.apiInstance.request<T>(config);
      return res.data;
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        if (e?.response?.status === 401) {
          throw new Error('Faça login novamente');
        }
        const errorMessage = e?.response?.data?.error?.message as string;
        const error = e?.response?.data?.message as string;
        throw new Error(errorMessage || error || 'Ocorreu um erro.');
      }
      throw e;
    }
  }

}



/* import { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/react';

export interface UseRequestProps {
  url: string;
  method: 'get' | 'post' | 'put' | 'delete';
  body: string;
  onSuccess: (data: any) => void;
  showSuccessToast?: boolean;
  showErrorToast?: boolean;
}

export const useRequest = ({
  url,
  method,
  body,
  onSuccess,
  showSuccessToast = false,
  showErrorToast = true,
}: UseRequestProps) => {
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast({
    position: 'top-right',
  });

  const doRequest = async () => {
    try {
      setLoading(true);
      setErrors(null);
      const response: AxiosResponse = await axios[method](url, body);

      if (onSuccess) {
        if (showSuccessToast) {
          toast({
            title: 'Success',
            description: response?.data?.message || 'Yay!',
            duration: 3000,
            status: 'success',
            isClosable: true,
          });
        }
        onSuccess(response.data);
      }

      return response.data;
    } catch (err: any) {
      const description =
        err?.response?.data?.errors ||
        err?.response?.data?.message ||
        err?.message ||
        'Something went wrong';
      setErrors(description);
      if (showErrorToast) {
        toast({
          title: 'Error',
          description,
          duration: 3000,
          status: 'error',
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return { doRequest, loading, errors };
};

// example usage:
// import { useRequest } from "../../hooks/useRequest";
//
// const { doRequest, loading, errors } = useRequest({
//   url: "/api/users/signup",
//   method: "post",
//   body: {
//     email,
 */
