import React from 'react';
import { Button } from '@chakra-ui/react';

interface CSVExportButtonProps {
  data: any[];
 children?: React.ReactNode;
}

const CSVExportButton: React.FC<CSVExportButtonProps> = ({ data,children }) => {
  const handleExportCSV = () => {
    const csvContent = 'data:text/csv;charset=utf-8,' + data.map(Object.values).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Button variant="outline" onClick={handleExportCSV}>
      {children}
    </Button>
  );
};

export default CSVExportButton;
