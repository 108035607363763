import { useContext } from "react";
import { UserContext } from "../../../../../Context/user.context";
import { IFilter } from "../../../../../Interfaces/IFilter";
import { ReceiptResult, ReportBasicFilterRequest, ReportNonconformityResult } from "../../../../interfaces/driver/receipt";
import { GetAllCompanies } from "../../../company";
import { GetNonconformity } from "../../noConformity";


//REQUISIÇÃO PARA A TELA DE RELATÓRIO DE ALERTAS GERAIS
export async function ListWarnings(userID: any, filter?: IFilter) {

    let finalreport: ReportNonconformityResult[] = [];
    
    const req: ReportBasicFilterRequest = {
        UserID: userID,
        CompanyID:undefined,
        Companies:filter?.companies,
        CostCenterID: filter?.costCenter,
        StartDate: filter?.periodStart,
        EndDate:  filter?.periodEnd,
        Productive: undefined,
        PaymentMethod: filter?.beneblex,
        providerid: filter?.provider,
        ReceiptStatusID:undefined,
        TransactionID: undefined
    }

    if (req.Companies?.some(item => item === "0") )
    {    
          const companies = await GetAllCompanies(userID);  

          for (const company of companies || []) {
            req.CompanyID = company.companyID

            const resReport2 = await GetNonconformity(req);
            finalreport = [...finalreport, ...resReport2];
          }
    }
  else
    {

        for (const company of req.Companies || []) {
            req.CompanyID = company;
        
            const resReport2 = await GetNonconformity(req);
            finalreport = [...finalreport, ...resReport2];          
        } 
    }

 return finalreport
}