import { ApiRepository, ApiResponse } from "../../..";
import { ProjectReportResult } from "../../../interfaces/driver/project";
import { ReportBasicFilterRequest } from "../../../interfaces/driver/receipt";
import { ReportRideRequest, RideReportResult } from "../../../interfaces/driver/ride";

export async function GetProject(req: ReportBasicFilterRequest) {

    const api = new ApiRepository(3);

    const queryfilter = "Reports/Project?companyID=" + req.CompanyID + "&startDate=" + req.StartDate + "&endDate=" + req.EndDate;

    //if (req.CostCenterID != 0)
    //    queryfilter += "&CostCenterID=" + req.CostCenterID;

    //if (req.PaymentMethod != null)
    //    queryfilter += "&PaymentMethod=" + req.PaymentMethod;     

    const res = await api.apiRequestWrapper<ApiResponse<ProjectReportResult[]>>({
        method: 'get',
        url: queryfilter,
    });

    return res.result
}