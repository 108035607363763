import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import PageContainer from '../../Components/Container/PageContainer';
import HotelCard from '../../Components/Card/HotelCard';
import PolicyCard from '../../Components/Card/PolicyCard';
import LodgingSearchForm from '../../Components/Form/LodgingSearchForm';
import LodgingFilterForm from '../../Components/Filter/LodgingFilter';

import { LodgingSearchFormValues } from '../../Types/lodgings';
import { lodgingData } from './data.mock';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';

export default function Lodgings() {
  const searchForm = useForm<LodgingSearchFormValues>();

  const handleSearch = async (data: LodgingSearchFormValues) => {
    console.log(data);
  };

  return (
    <PageContainer>
      <Heading>Busca</Heading>
      <Text mb={12}>Pesquise a melhor hospedagem para a sua viagem.</Text>

      <LodgingSearchForm
        form={searchForm}
        onSubmit={handleSearch}
        stackProps={{ mb: 3 }}
      />
      <LodingFiltersDropdown form={searchForm} handleSearch={handleSearch} />

      <HStack mt={{ base: 6, lg: 12 }} w="100%" gap={3} alignItems="start">
        <Stack display={{ base: 'none', lg: 'block' }} flex={1 / 5} gap={6}>
          <LodgingFilterForm form={searchForm} onSubmit={handleSearch} />
          <PolicyCard display={{ base: 'none', lg: 'block', '2xl': 'none' }} />
        </Stack>

        <Box h="100%" flex={{ base: 1, lg: 4 / 5, '2xl': 3 / 5 }}>
          {lodgingData.map((h) => {
            return (
              <HotelCard
                key={h.hotelID}
                hotelName={h.hotelName}
                dailyPrice={h.dailyPrice}
                downtownDistance={h.downtownDistance}
                hotelGrade={h.hotelGrade}
                hotelID={h.hotelID}
              />
            );
          })}
        </Box>
        <PolicyCard display={{ base: 'none', '2xl': 'block' }} flex={1 / 5} />
      </HStack>
    </PageContainer>
  );
}

function LodingFiltersDropdown({ form, handleSearch }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack display={{ lg: 'none' }} w="100%">
      <Button
        w="100%"
        leftIcon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        aria-label={'Open Menu'}
        onClick={isOpen ? onClose : onOpen}
      >
        Filtros
      </Button>
      <Box display={isOpen ? 'block' : 'none'}>
        <LodgingFilterForm form={form} onSubmit={handleSearch} />
      </Box>
    </Stack>
  );
}
