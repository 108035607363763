import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  HStack,
  Stack,
} from '@chakra-ui/react';

export interface EmptyModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'
}

export default function EmptyModal({
  isOpen,
  onClose,
  title,
  children,
  primaryBtnText,
  secondaryBtnText,
  size
}: EmptyModalProps) {
  return (
    <Modal size={size ? size : '3xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>{children}</Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            {primaryBtnText && <Button>{primaryBtnText}</Button>}
            {secondaryBtnText && (
              <Button variant="secondary">{secondaryBtnText}</Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
