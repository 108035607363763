import React from 'react';
import { Button } from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import { PaymentDriverResult } from '../../../Api/interfaces/driver/paymets';

interface ExportToExcelButtonProps {
    children?: React.ReactNode;  
    data: PaymentDriverResult[];
}

const ExportToExcelButton: React.FC<ExportToExcelButtonProps> = ({ data, children }) => {
  const exportToExcel = () => {

    const reorderedData = data.map(item => ({
      'ID da Transação': item.transactionID,
      'ID da Corrida': item.rideID,
      'Empresa': item.company,
      'Nome': item.name,
      'Data': item.create,
      'Fornecedor': item.provider,
      'Preço Estimado': item.estimated,
      'Preço Pago': item.price,
      'Telefone': item.phone,
      'Distância (km)': item.distancy,
      'Duração (min)': item.duration,
      'Categoria': item.category,
      'Origem': item.startAddress,
      'Latitude Origem': item.startLat,
      'Longitude Origem': item.startLng,
      'Destino': item.endAddress,
      'Latitude Destino': item.endLat,
      'Longitude Destino': item.endLng,
      'Produto': item.product,
      'Motorista': item.driver,
      'Carro': item.car,
      'Placa': item.plate
    }));

    const ws = XLSX.utils.json_to_sheet(reorderedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'corridas_realizadas.xlsx');
  };

  return (
    <Button variant="outline" onClick={exportToExcel}>
      {children}
    </Button>
  );
};

export default ExportToExcelButton;


