import { Center, Flex, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';

import Logo from '../../../Assets/logo.png';

interface PageContainerProps {
  children: ReactNode | ReactNode[];
}

export default function PageContainer({ children }: PageContainerProps) {
  return (
    <>
      <Flex
        direction="column"
        w="100%"
        flex={1}
        px={{ base: 4, md: '10%', xl: '15%', '2xl': '20%' }}
        pt={8}
        pb={8}
        minHeight="70vh"
      >
        {children}
      </Flex>
    </>
  );
}
