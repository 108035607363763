import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { TableProps } from './types';

export const TableDriver = ({ tableText, tableBody = [] }: TableProps) => {
  
  return (
    <TableContainer>
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            {tableText.map((item: any, idx: number) => (
              <Th key={idx}>{item.text}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {
            tableBody.map((row: any, idx: number) => (
              <Tr key={idx}>
                {
                  tableText.map((column: any, idx: number) => (
                    <Td key={idx}>
                      {row[column.value]}
                    </Td>
                  ))
                }
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>
  );
};
