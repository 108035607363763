import { useContext } from "react";
import { ApiRepository, ApiResponse } from "../../../..";
import { UserContext } from "../../../../../Context/user.context";
import { IFilter } from "../../../../../Interfaces/IFilter";
import { ReportBasicFilterRequest } from "../../../../interfaces/driver/receipt";
import { ReportRideEstimativeResult, ReportRideRequest, RideReportResult } from "../../../../interfaces/driver/ride";
import { GetAllCompanies } from "../../../company";
import { GetProject } from "../../project";
import { GetReportEstimative } from "../../reportEstimative";
import { GetReport } from "../../ride";


//REQUISIÇÃO PARA A TELA CORRIDAS ESTIMADAS
export async function ListRideEstimative(userID: any, filter?: IFilter) {
 
    let finalreport: ReportRideEstimativeResult[] = [];
    
    const req: ReportBasicFilterRequest = {
        UserID: userID,
        CompanyID:undefined,
        Companies:filter?.companies,
        CostCenterID: filter?.costCenter,
        StartDate: filter?.periodStart,
        EndDate:  filter?.periodEnd,
        Productive: undefined,
        PaymentMethod: filter?.beneblex,
        providerid: filter?.provider,
        ReceiptStatusID:undefined,
        TransactionID: undefined
    }

    if (req.EndDate != null){
      req.EndDate += " 23:59:59"
    };

        if (req.Companies?.some(item => item === "0") )
        {    
              const companies = await GetAllCompanies(userID);      
              
              for (const company of companies || []) {
                req.CompanyID = company.companyID
                const resReport = await GetReportEstimative(req);
                finalreport = [...finalreport, ...resReport] 
              } 
        }
        else
        {
            for (const company of req.Companies || []) {
              req.CompanyID = company;
              const resReport = await GetReportEstimative(req);
              finalreport = [...finalreport, ...resReport];
            }  
        }

    return finalreport
}