import { ApiRepository, ApiResponse } from "../../../..";
import { IFilter } from "../../../../../Interfaces/IFilter";
import { AttestAndContestRequest, AttestAndContestResult } from "../../../../interfaces/driver/atest_contest";
import { ReceiptResult } from "../../../../interfaces/driver/receipt";

//REQUISIÇÃO PARA AS TELAS DE ATESTE/CONTEST
export async function GetAtestContest(userID: any,filter?: IFilter) {

    const api = new ApiRepository(2);

    function formatDateRate(dateString?: string) {
        const parts = dateString?.split('/');
        const formattedDate = `${parts?.[1]}/${parts?.[0]}/${parts?.[2]}`;

        return formattedDate;
    }

    const dataSaida = formatDateRate(filter?.periodStart)
    const dataChegada= formatDateRate(filter?.periodEnd)

    const res = await api.apiRequestWrapper<ApiResponse<ReceiptResult[]>>({
        method: 'get',
        url: `AttestAndContest/GetReceipts?UserID=${userID}&StartDate=${dataSaida}&EndDate=${dataChegada}`,
    });

    return res.result
}

export async function GetAtestContestDetails(receiptID: number) {

    const api = new ApiRepository(2);

    const res = await api.apiRequestWrapper<ApiResponse<ReceiptResult>>({
        method: 'get',
        url: `/Receipt/${receiptID}`,
    });

    return res.result
}

export async function PostAtestContest(req: AttestAndContestRequest) {

    const api = new ApiRepository(2);

    const res = await api.apiRequestWrapper<ApiResponse<AttestAndContestResult>>({
        method: 'post',
        url: `AttestAndContest`,
        data: req
    });

    return res.result
}

export async function PostAtestContestUser(req: AttestAndContestRequest) {

    const api = new ApiRepository(2);

    const res = await api.apiRequestWrapper<ApiResponse<AttestAndContestResult>>({
        method: 'post',
        url: `AttestAndContest/User`,
        data: req
    });

    return res.result
}

export async function GetAtestContestDetailsUser(user: string | null) {

    const api = new ApiRepository(2);

    const res = await api.apiRequestWrapper<ApiResponse<ReceiptResult | null>>({
        method: 'get',
        url: `/Receipt/User?Hash=${user}`,
    });

    return res.result
}