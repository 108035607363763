import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const CardStyle = defineMultiStyleConfig({
  variants: {
    rounded: definePartsStyle({
      container: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        bg: 'white',
      },
      header: {},
      body: {},
      footer: {},
    }),
  },
  defaultProps: {
    variant: 'rounded',
  },
});

export default CardStyle;
