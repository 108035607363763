const CheckboxStyles = {
    variants: {
        petroSolid: {
            control: {
                padding: '2',
                borderColor: 'orange',
                borderRadius: '0',
                _checked: {
                    bg: 'orange',
                    borderColor: 'orange',
                    color: 'orange',
              
                    _hover: {
                      bg: 'orange',
                      borderColor: 'orange',
                    },
                  },
            },
            label: {
                fontSize: '16px'
            },
        },
        petroSolid2: {
            control: {
                padding: '2',
                borderColor: 'orange',
                borderRadius: '0',
                _checked: {
                    bg: 'orange',
                    borderColor: 'orange',
                    color: 'white',
              
                    _hover: {
                      bg: 'orange',
                      borderColor: 'orange',
                    },
                  },
            },
            label: {
                fontSize: '16px'
            },
        },
      },
      defaultProps: {
          variant: 'petroSolid',
        },
}

export default CheckboxStyles;