import { ApiRepository, ApiResponse } from "../../..";
import { IFilter } from "../../../../Interfaces/IFilter";
import { ReceiptResult, ReportBasicFilterRequest } from "../../../interfaces/driver/receipt";

export async function Filter(req: ReportBasicFilterRequest) {

    const api = new ApiRepository(2);

    let queryFilter = `Receipt?UserID=${req.UserID}&StartDate=${req.StartDate}&EndDate=${req.EndDate}`;

        if (req.ReceiptStatusID != undefined && req.ReceiptStatusID > 0)
            queryFilter += "&ReceiptStatusID=" + req.ReceiptStatusID
        if (req.TransactionID != undefined )
            queryFilter += "&TransactionID=" + req.TransactionID
        if (req.providerid != undefined && parseInt(req.providerid) > 0)
            queryFilter +=  "&ProviderID=" + req.providerid
        if (req.CompanyID != undefined )
            queryFilter += "&CompanyID=" + req.CompanyID
        if (req.CostCenterID != undefined && req.CostCenterID > 0 )
            queryFilter += "&CostCenterID=" + req.CostCenterID    

    const res = await api.apiRequestWrapper<ApiResponse<ReceiptResult[]>>({
        method: 'get',
        url: queryFilter,
    });

    return res.result
}

export async function GetDashboard(filter?: IFilter) {
    
    let api = new ApiRepository(2);
  
    const res = await api.apiRequestWrapper<ApiResponse<number>>({
        method: 'get',
        url: `Receipt/Dashboard?ProviderID=${filter?.providerid}}`,
    });
    return res;
}