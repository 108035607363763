import { CalendarIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Flex,
  Heading,
  Stack,
  Box,
  Text,
  Select,
  Input,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PaymentDriverResult } from '../../../Api/interfaces/driver/paymets';
import { TableDriver } from '../../../Components/TableDriver';
import tableText from './table.json';
import DefaultFilter from '../../../Components/Filter/DefaultFilter';
import PageContainer from '../../../Components/Container/PageContainer';
import { IFilter } from '../../../Interfaces/IFilter';
import { useService } from '../../../Hooks/useService';
import ExportToExcelButton from '../../../Components/Buttons/ExcelButton';
import CSVExportButton from '../../../Components/Buttons/CSVButton';

export const RacesHeld = () => {
  const { ListRideService } = useService()
  const [tableData, setTableData] = useState<PaymentDriverResult[]>([])
  const [itensForPage, setItensForPage] = useState(10)
  const [atualPage, setAtualPage] = useState(0)
  const [search, setSearch] = useState("")

  const getValueTable = async (filter?: IFilter) => {
    const res: any = await ListRideService(filter)
    setTableData(res.result.result)
  }

  const handlePreview = () => {
    setAtualPage(page => (page === 0 ? 0 : page - 1))
  }

  const handleNext = () => {
    setAtualPage(page => (page < getLimit() - 1 ? page + 1 : page))
  }

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItensForPage(Number(e.target.value))
    setAtualPage(0)
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setAtualPage(0)
  }

  const getLimit = () => {
    return Math.ceil(tableData.filter(item => (search ? JSON.stringify(item).toLowerCase().includes(search.toLowerCase()) : true)).length / itensForPage)
  }

  useEffect(() => {
    getValueTable()
  }, [])

  const filterTable = tableData
    .filter(item => (search ? JSON.stringify(item).toLowerCase().includes(search.toLowerCase()) : true))
    .slice(atualPage * itensForPage, (atualPage + 1) * itensForPage)

  return (
    <PageContainer>
      <Flex direction="column" marginBottom={10}>
        <Heading>Corridas Realizadas</Heading>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Corridas Realizadas</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      <DefaultFilter onClickFilter={getValueTable} multiCompany />

      <Card>
        <CardBody>
          <Flex justifyContent="space-between">
            <Flex alignItems="center" gap={2}>
              <Select value={itensForPage} width="30%" onChange={handleSelect}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Select>
              <Text>Resultados por páginas</Text>
            </Flex>
            <Flex gap={2} alignItems="center">
              <Text>Pesquisar</Text>
              <Input type="text" size="md" placeholder='valor' onChange={handleChangeInput} />
              <CSVExportButton data={tableData}>CSV</CSVExportButton>
              <ExportToExcelButton data={tableData}>Excel</ExportToExcelButton>
            </Flex>
          </Flex>

          <Stack marginTop={10} marginBottom={10}>
            <TableDriver tableText={tableText} tableBody={filterTable} />
          </Stack>
          <Flex gap={2}>

            <Button variant="outline" onClick={handlePreview} disabled={atualPage === 0}>
              Anterior
            </Button>
            <Button variant="outline" onClick={handleNext} disabled={atualPage >= getLimit() - 1}>
              Próximo
            </Button>

          </Flex>
        </CardBody>
      </Card>
    </PageContainer>
  );
};
