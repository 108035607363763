import { StarIcon } from '@chakra-ui/icons';
import { HStack, StackProps } from '@chakra-ui/react';

export interface GradeViewProps {
  hotelGrade: number;
}

const GradeView = ({ hotelGrade, ...props }: GradeViewProps & StackProps) => {
  return (
    <HStack {...props}>
      {Array(hotelGrade).fill(<StarIcon color="#FF9529" />)}
      {Array((hotelGrade - 5) * -1).fill(<StarIcon color="#9c9c9c" />)}
    </HStack>
  );
};

export default GradeView;
