import { ApiRepository, ApiResponse } from "../../..";
import { ReportBasicFilterRequest } from "../../../interfaces/driver/receipt";
import { ReportRideEstimativeResult, ReportRideRequest, RideReportResult } from "../../../interfaces/driver/ride";

export async function GetReportEstimative(req: ReportBasicFilterRequest) {
    const api = new ApiRepository(2);

    const queryfilter ="RideEstimative/Report?companyID=" + req.CompanyID
                            + "&startDate=" + req.StartDate
                            + "&endDate=" + req.EndDate;

    const res = await api.apiRequestWrapper<ApiResponse<ReportRideEstimativeResult[]>>({
        method: 'get',
        url: queryfilter,
    });

    return res.result
}