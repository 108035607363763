import { IFilter } from "../../../../../Interfaces/IFilter";
import { ReceiptResult, ReportBasicFilterRequest } from "../../../../interfaces/driver/receipt";
import { GetAllCompanies } from "../../../company";
import { Filter } from "../../receipt";


//REQUISIÇÃO PARA A TELA CORRIDAS NÃO RECONCILIADAS PENDENTES 
export async function ListRideUnreconciled(userID: any, filter?: IFilter) {

    let finalreport: ReceiptResult[] = [];
    
    const req: ReportBasicFilterRequest = {
        UserID: userID,
        CompanyID:undefined,
        Companies:filter?.companies,
        CostCenterID: filter?.costCenter,
        StartDate: filter?.periodStart,
        EndDate:  filter?.periodEnd,
        Productive: undefined,
        PaymentMethod: filter?.beneblex,
        providerid: filter?.provider,
        ReceiptStatusID:undefined,
        TransactionID: undefined
    }

    if (req.Companies?.some(item => item === "0") )
    {    
          const companies = await GetAllCompanies(userID);  

          for (const company of companies || []) {
            req.CompanyID = company.companyID

            const resReport = await Filter(req);
            finalreport = [...finalreport, ...resReport];
          }
    }
  else
    {
        for (const company of req.Companies || []) {
            req.CompanyID = company;
        
            const resReport = await Filter(req);
            finalreport = [...finalreport, ...resReport];    
        } 
    }

return finalreport
}