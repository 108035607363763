import {
	Flex,
	Heading,
	Stack,
	Center,
	Divider,
	VStack,
	Link,
	Toast,
	useToast,
    Image
} from "@chakra-ui/react";
import { AiOutlineLock } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
// import { useAppDispatch, useAppSelector } from '../src/store/hooks';
// import { UserActions } from '../src/store/slices/user';
// import { selectUser } from '@themis/legi-monitoring/store/slices/user';
import { useForm } from "react-hook-form";
import { ButtonLink } from "../../Components/Buttons/ButtonLink";
import { ButtonSolid } from "../../Components/Buttons/ButtonSolid";
import Form from "../../Components/RHF/Form";
import TextInput from "../../Components/RHF/TextInput";
import { useContext, useEffect } from "react";
import { LoginCheck } from "../../Api/services/auth";
//import { useRouter } from "next/router";
import logo from '../../Assets/logo.png';
import { Navigate } from "react-router-dom";
import UserContextProvider, { UserContext } from "../../Context/user.context";
import { useNavigate } from "react-router-dom";


interface LoginForm {
	username: string;
	password: string;
}

export default function LoginPage() {

	const { login } = useContext(UserContext);
	const navigate = useNavigate();

	useEffect(() => {
		//VERIFICAR SE O USUÁRIO JÁ ESTÁ LOGADO E CASO SIM REDIRECIONAR PARA A HOME
	}, []);

	const toast = useToast();

	const { register, handleSubmit } = useForm<LoginForm>();

	const onSubmit = async (data: LoginForm) => {
		try {
			const res = await login(data.username, data.password)
			if(res === 200){		
				navigate("/");
			}
		} catch (error) {
			toast({
				title: "Erro ao fazer login",
				description: "Verifique suas credenciais e tente novamente",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	};

	return (
		<div
			style={{
				height: "100vh",
			}}
		>
			<Center h={"100%"} width={"100%"} backgroundColor={"blue"}>
				<Stack
					minH={{ md: "60vh", base: "80vh" }}
					width={{ base: "90%", xl: "70%" }}
					direction={{ base: "column", md: "row" }}
					backgroundColor={"white"}
					borderRadius={"10px"}
				>
					<Flex flex={1} align={"center"} justify={"center"}>
                        <Image src={logo} width={250} />
					</Flex>
					<Center
						display={{ base: "none", md: "flex" }}
						paddingTop={"5%"}
						paddingBottom={"5%"}
					>
						<Divider
							orientation="vertical"
							borderWidth={"1px"}
							borderColor={"blue"}
						/>
					</Center>
					<VStack
						p={{ base: 6, md: 8 }}
						pt={{ base: 8, md: 16 }}
						pb={{ base: 8, md: 16 }}
						align={"center"}
						justify={"space-between"}
						flex={1}
					>
						<Form
							onSubmit={handleSubmit((v) => onSubmit(v))}
							style={{
								display: "flex",
								flexWrap: "nowrap",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
								height: "100%",
								gap: 24,
							}}
						>
							<Heading
								fontSize={"2xl"}
								fontWeight={"normal"}
								color={"blue"}
								mb={8}
							>
								Login
							</Heading>
							<Flex
								w={{ base: "90%", xl: "60%" }}
								direction={"column"}
								align={"center"}
								justify={"center"}
							>
								<TextInput
									rhfregister={register("username")}
									id={"email"}
									type={"email"}
									placeholder="Email"
									icon={<BiUser size={35} />}
									containerProps={{ width: "full" }}
								/>
								<TextInput
									rhfregister={register("password")}
									id={"password"}
									type={"password"}
									placeholder="Senha"
									icon={<AiOutlineLock size={35} />}
									containerProps={{ width: "full" }}
								/>
							</Flex>
							<Stack
								direction={"column"}
								align={"center"}
								justify={"space-between"}
								w={"full"}
								mt={{ base: 8 }}
								spacing={12}
							>

								<ButtonSolid type={"submit"} title="Entrar" />

								{/* <ButtonLink title="Cadastre-se" /> */}
							</Stack>
						</Form>
					</VStack>
				</Stack>
			</Center>
		</div>
	);
}
