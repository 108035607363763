import {
  Box,
  Card,
  CardBody,
  Stack,
  StackDivider,
  Heading,
  Checkbox,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Text,
  HStack,
} from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { LodgingSearchFormValues } from '../../../Types/lodgings';
import GradeSlider from '../../Form/Slider/GradeSlider';

interface LodgingFilterFormProps {
  form: UseFormReturn<LodgingSearchFormValues, any>;
  onSubmit: (data: LodgingSearchFormValues) => Promise<void>;
}

export default function LodgingFilterForm({
  form,
  onSubmit,
}: LodgingFilterFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  return (
    <Card>
      <CardBody>
        <Stack spacing={3}>
          <Stack divider={<StackDivider />}>
            <Heading size={'md'} pl={1}>
              Categoria do Hotel
            </Heading>

            <GradeSlider rhfRegister={register} alignSelf="center" w="80%" />
          </Stack>

          <Stack divider={<StackDivider />}>
            <Heading size={'md'} pl={1}>
              Comodidades
            </Heading>
            <Stack spacing={4} pl={1}>
              <Checkbox>Academia</Checkbox>
              <Checkbox>Ar-Condicionado</Checkbox>
              <Checkbox>Wi-Fi</Checkbox>
            </Stack>
          </Stack>

          <Stack divider={<StackDivider />}>
            <Heading size={'md'} pl={1}>
              Valor da diária
            </Heading>
            <Box>
              <Box pl={1}>
                <Heading size={'xs'}>Valor</Heading>
                <HStack>
                  <Text>R$0</Text>
                  <Text>-</Text>
                  <Text>R$ 800</Text>
                </HStack>
              </Box>
              <RangeSlider
                aria-label={['min', 'max']}
                colorScheme={'green'}
                defaultValue={[0, 100]}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb boxSize={'20px'} index={0} />
                <RangeSliderThumb index={1} />
              </RangeSlider>
            </Box>
          </Stack>

          <Stack divider={<StackDivider />}>
            <Heading size={'md'} pl={1}>
              Distância do centro
            </Heading>
            <Box>
              <Box pl={1}>
                <Heading size={'xs'}>Km</Heading>
                <HStack>
                  <Text>1km</Text>
                  <Text>-</Text>
                  <Text>10km</Text>
                </HStack>
              </Box>
              <RangeSlider
                aria-label={['min', 'max']}
                colorScheme={'green'}
                defaultValue={[0, 100]}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb boxSize={'20px'} index={0} />
                <RangeSliderThumb index={1} />
              </RangeSlider>
            </Box>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
}
